import { NgModule } from "@angular/core";
import { DefaultModules } from "src/app/services/app.constants";
import { TactusTimelineComponent } from "./tactus-timeline.component";
import { RadioButtonModule } from "primeng/radiobutton";
import { FormsModule } from "@angular/forms";
import { CheckboxModule } from "primeng/checkbox";

@NgModule({
  declarations: [TactusTimelineComponent],
  imports: [...DefaultModules, RadioButtonModule, FormsModule, CheckboxModule],
  exports: [TactusTimelineComponent],
})
export class TactusTimelineModule {}
