import { NgModule } from "@angular/core";
import { DefaultModules } from "../services/app.constants";
import { HeaderComponent } from "./components/header/header.component";
import { HeaderService } from "./components/header/header.service";
import { SidebarService } from "./components/sidebar/sidebar.service";
import { SidebarComponent } from "./components/sidebar/sidebar.component";
import { ThemeService } from "./components/theme.service";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { ShellLayoutComponent } from "./components/layout/shell-layout.component";
import { EmptyLayoutComponent } from "./components/layout/empty-layout/empty-layout.component";
import { NgbModule } from "@ng-bootstrap/ng-bootstrap";
import { TranslateModule } from "@ngx-translate/core";
import { NewsAnimatorComponent } from "./components/news-animator/news-animator.component";
import { SplitButtonModule } from "primeng/splitbutton";
import { BreadcrumbModule } from "./components/breadcrumb/breadcrumb.module";
import { TooltipModule } from "primeng/tooltip";
import { PatientsLayoutComponent } from "./components/layout/patients-layout/patients-layout.component";
import { PatientAppointmentsLayoutComponent } from "./components/layout/patient-appointments-layout/patient-appointments-layout.component";
import { FormsModule } from "@angular/forms";
import { InputTextModule } from "primeng/inputtext";
import { MobileSidebarComponent } from "./components/mobile-sidebar/mobile-sidebar.component";
import { SidebarModule } from "primeng/sidebar";
import { ButtonModule } from "primeng/button";

const components = [
  HeaderComponent,
  SidebarComponent,
  ShellLayoutComponent,
  EmptyLayoutComponent,
  NewsAnimatorComponent,
];
const modules = [
  TranslateModule.forChild(),
  NgbModule,
  BrowserAnimationsModule,
  SplitButtonModule,
  BreadcrumbModule,
  FormsModule,
  InputTextModule,
  TooltipModule,
  SidebarModule,
  ButtonModule,
];
const providers = [HeaderService, SidebarService, ThemeService];

@NgModule({
  imports: [...DefaultModules, ...modules],
  exports: [...components],
  providers: [...providers],
  declarations: [...components, PatientsLayoutComponent, PatientAppointmentsLayoutComponent, MobileSidebarComponent],
})
export class ThemeModule {}
