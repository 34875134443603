<form [formGroup]="form" (ngSubmit)="save()" *ngIf="options.length && homeCareOptions.length">
  <!-- <div class="p-grid">
    <div class="p-col-2">
      <label>{{ "medicalRecord.observation" | translate }}:</label>
    </div>
    <div class="p-col-2">
      <input pInputText formControlName="Observation" type="text" />
    </div>
  </div> -->
  <div class="p-grid">
    <div class="p-col-2">
      <label>{{ "Options" | translate }}:</label>
    </div>
    <div class="p-col-2">
      <p-dropdown
        [options]="options"
        formControlName="Observation"
        optionLabel="name"
        [placeholder]="'Select an option' | translate"
        [showClear]="true"
        dataKey="value"
      ></p-dropdown>
    </div>
    <div *ngIf="selectedOption?.value === 'homeCareAssistance'">
      <div class="p-col-2">
        <label>{{ "Options" | translate }}:</label>
      </div>
      <div class="p-col-2">
        <p-dropdown
          [options]="homeCareOptions"
          formControlName="Value"
          optionLabel="name"
          [placeholder]="'Select an option' | translate"
          [showClear]="true"
        ></p-dropdown>
      </div>
    </div>
    <div
      *ngIf="
        ['other', 'lifestyle', 'medServicesAccess', 'socialCareServices', 'elderlyCenter', 'houseChars'].includes(
          selectedOption?.value
        )
      "
    >
      <div class="p-1"></div>
      <input pInputText type="text" formControlName="Value" [placeholder]="'Add information here' | translate" />
    </div>
  </div>
  <br />
  <div class="p-grid">
    <div class="p-col-2">
      <label>{{ "medicalRecord.date" | translate }}:</label>
    </div>
    <div class="p-col-2" style="max-width: 200px">
      <p-calendar formControlName="Date" yearNavigator="true" monthNavigator="true" yearRange="1910:2030"></p-calendar>
    </div>
  </div>
  <br />
  <div>
    <button
      type="submit"
      class="p-button-rounded"
      icon="fas fa-check"
      [label]="'general.button.texts.save' | translate"
      pButton
    ></button>
    <span class="m-2"></span>
    <button
      (click)="onClose()"
      class="p-button-rounded p-button-danger"
      icon="fas fa-times"
      [label]="'general.button.texts.Cancel' | translate"
      type="button"
      pButton
    ></button>
  </div>
</form>
