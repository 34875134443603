<div class="patient-record">
  <div class="patient-info mb-3">
    <div class="patient-info-details" *ngIf="isCaregiver">
      <span>{{ "Caregiver for" | translate }}: </span>
    </div>
    <div class="patient-info-details" *ngIf="patientInfo.Fullname">
      <span class="text-muted">{{ "medicalRecord.name" | translate }}:&nbsp;</span>{{ patientInfo.Fullname }}
    </div>
    <div class="patient-info-details" *ngIf="patientInfo.Age">
      <span class="text-muted">{{ "medicalRecord.Age" | translate }}:&nbsp;</span>{{ patientInfo.Age }}
    </div>
    <div class="patient-info-details" *ngIf="patientInfo.DateOfBirth">
      <span class="text-muted">{{ "editPatientInfo.DateOfBirth" | translate }}:&nbsp;</span
      >{{ patientInfo.DateOfBirth | date : "mediumDate" : "" }}
    </div>
    <div class="patient-info-details" *ngIf="patientInfo.Email">
      <span class="text-muted">{{ "patientInfoList.Email" | translate }}:&nbsp;</span>{{ patientInfo.Email }}
    </div>
    <div class="patient-info-details" *ngIf="patientInfo.cia && patientInfo.cia !== ''">
      <span class="text-muted">{{ "CIA" | translate }}:&nbsp;</span>{{ patientInfo.cia }}
    </div>
    <div class="p-1"></div>
    <div class="separator"></div>
    <div class="p-1"></div>
    <div>
      <button pButton (click)="showInviteCaregiverForm()">{{ "InviteCaregiver" | translate }}</button>
      <ng-container *ngIf="authenticationService.hasRole('ROLE_CARE_COORDINATOR')">
        <span class="p-1"></span>
        <button pButton (click)="showPatientCareTeam()">{{ "careplan.CareTeam" | translate }}</button>
      </ng-container>
    </div>
  </div>

  <div *ngIf="!wizardCompleted" class="card">
    <div class="steps-title">
      <h3 class="text-center">{{ "PatientRecord.steps.newPatientWizard" | translate }}</h3>
      <h6 class="text-center">{{ "PatientRecord.steps.thisIsTheFirstTime" | translate }}</h6>
    </div>

    <p-steps class="steps-custom" [model]="items" [(activeIndex)]="activeIndex"></p-steps>

    <!-- ---------------- STEP 0 ----------------- -->
    <div *ngIf="activeIndex === 0" class="p-3">
      <app-account-step [patientId]="patientId"></app-account-step>
    </div>

    <!-- ---------------- STEP 1 ----------------- -->
    <div *ngIf="activeIndex === 1" class="p-3">
      <app-conditions-step></app-conditions-step>
    </div>

    <!-- ---------------- STEP 2 ----------------- -->
    <div *ngIf="activeIndex === 2" class="p-3">
      <app-history-step></app-history-step>
    </div>

    <!-- ---------------- STEP 3 ----------------- -->
    <div *ngIf="activeIndex === 3" class="p-3">
      <app-procedures-step></app-procedures-step>
    </div>

    <!-- ---------------- STEP 4 ----------------- -->
    <div *ngIf="activeIndex === 4" class="p-3">
      <h4 class="main-title mb-3">{{ "general.button.texts.Submit" | translate }}</h4>
      <h5 class="sub-title">{{ "PatientRecord.steps.wizardEndFirstLine" | translate }}</h5>
      <h5 class="sub-title">{{ "PatientRecord.steps.wizardEndSecondLine" | translate }}</h5>
    </div>

    <div class="d-flex justify-content-between p-3">
      <button
        pButton
        [label]="'general.button.texts.Previous' | translate"
        [disabled]="activeIndex === 0"
        (click)="previous()"
      ></button>
      <button
        *ngIf="activeIndex !== 4"
        pButton
        [label]="'general.button.texts.Next' | translate"
        (click)="next()"
      ></button>
      <button
        *ngIf="activeIndex === 4"
        pButton
        [label]="'general.button.texts.Submit' | translate"
        (click)="finishWizard()"
      ></button>
    </div>
  </div>

  <div *ngIf="wizardCompleted">
    <div class="record-menu d-none d-lg-block bottom-border">
      <div [routerLink]="getLink('dashboard')" routerLinkActive="active">
        {{ "PatientRecord.Dashboard" | translate }}
      </div>
      <div *ngIf="patientRestrictedAccess" [routerLink]="getLink('careplan/current')" routerLinkActive="active">
        {{ "PatientRecord.CarePlan" | translate }}
      </div>
      <div
        *ngIf="!patientRestrictedAccess"
        [routerLink]="getLink('careplan/list')"
        routerLinkActive="active"
        (click)="sendGoogleAnalytics('careplan/list')"
      >
        {{ "PatientRecord.CarePlan" | translate }}
      </div>
      <!-- <div [routerLink]="getLink('calendar')" routerLinkActive="active">{{'PatientRecord.Calendar'|translate}}</div> -->
      <div
        [routerLink]="getLink('medical-record')"
        routerLinkActive="active"
        (click)="sendGoogleAnalytics('medical-record')"
      >
        {{ "PatientRecord.MedicalRecord" | translate }}
      </div>
      <!-- <div [routerLink]="getLink('risk-score')" routerLinkActive="active" *ngIf="patientRestrictedAccess">
        {{ "Maggic Risk" | translate }}
      </div> -->
      <!-- <div
        [routerLink]="getLink('nutritional-habits')"
        routerLinkActive="active"
        *ngIf="patientRestrictedAccess"
        (click)="sendGoogleAnalytics('nutritional-habits')"
      >
        {{ "PatientRecord.NutritionalHabits" | translate }}
      </div> -->
      <div [routerLink]="getLink('devices')" routerLinkActive="active">{{ "PatientRecord.Devices" | translate }}</div>
      <div [routerLink]="getLink('patient-services')" routerLinkActive="active">
        {{ "Services/Apps" | translate }}
      </div>
      <!-- <div [routerLink]="getLink('measurements')" routerLinkActive="active">{{'PatientRecord.Measurements'|translate}}</div> -->
      <div *ngIf="patientRestrictedAccess" [routerLink]="getLink('patient-notifications')" routerLinkActive="active">
        {{ "sidebar.Notifications" | translate }}
      </div>
      <!-- <div [routerLink]="getLink('patient-rules')" *ngIf="patientRestrictedAccess" routerLinkActive="active">
        {{ "PatientRecord.PatientRules" | translate }}
      </div> -->
      <div [routerLink]="getLink('my-doctors')" *ngIf="!patientRestrictedAccess" routerLinkActive="active">
        {{ "CareTeam" | translate }}
      </div>
      <!-- <div [routerLink]="getLink('volunteer-training')" *ngIf="patientRestrictedAccess" routerLinkActive="active">
        {{ "PatientRecord.VolunteerTraining" | translate }}
      </div>
      <div
        [routerLink]="getLink('nutrition-notifications')"
        *ngIf="patientRestrictedAccess"
        routerLinkActive="active"
        (click)="sendGoogleAnalytics('nutritional-notifications')"
      >
        {{ "PatientRecord.NutritionNotifications" | translate }}
      </div> -->
      <div [routerLink]="getLink('history')" routerLinkActive="active">{{ "PatientRecord.History" | translate }}</div>
      <!-- <div [routerLink]="getLink('diabetes-diagnosis')" routerLinkActive="active">
        {{'PatientRecord.DiabetesDiagnosis'|translate}}</div> -->
    </div>
    <div class="d-lg-none">
      <div ngbDropdown placement="bottom-left" class="d-inline-block">
        <button class="btn btn-outline-primary" ngbDropdownToggle>
          <i class="fas fa-bars fa-fw"></i> {{ "general.Menu" | translate }}
        </button>
        <div ngbDropdownMenu>
          <button ngbDropdownItem [routerLink]="getLink('dashboard')" routerLinkActive="active">
            {{ "Dashboard" | translate }}
          </button>
          <button
            *ngIf="patientRestrictedAccess"
            ngbDropdownItem
            [routerLink]="getLink('careplan/current')"
            routerLinkActive="active"
          >
            {{ "PatientRecord.CarePlan" | translate }}
          </button>
          <button
            *ngIf="!patientRestrictedAccess"
            ngbDropdownItem
            [routerLink]="getLink('careplan/list')"
            routerLinkActive="active"
            (click)="sendGoogleAnalytics('careplan/list')"
          >
            {{ "PatientRecord.CarePlan" | translate }}
          </button>
          <!-- <button ngbDropdownItem [routerLink]="getLink('calendar')" routerLinkActive="active">{{'PatientRecord.Calendar'|translate}}</button> -->
          <button
            ngbDropdownItem
            [routerLink]="getLink('medical-record')"
            routerLinkActive="active"
            (click)="sendGoogleAnalytics('medical-record')"
          >
            {{ "PatientRecord.MedicalRecord" | translate }}
          </button>
          <!-- <button
            ngbDropdownItem
            [routerLink]="getLink('nutritional-habits')"
            *ngIf="patientRestrictedAccess"
            routerLinkActive="active"
            (click)="sendGoogleAnalytics('nutritional-habits')"
          >
            {{ "PatientRecord.NutritionalHabits" | translate }}
          </button> -->
          <!-- <button ngbDropdownItem [routerLink]="getLink('devices')" routerLinkActive="active">
            {{ "PatientRecord.Devices" | translate }}
          </button> -->
          <!-- <button ngbDropdownItem [routerLink]="getLink('measurements')" routerLinkActive="active">{{'PatientRecord.Measurements'|translate}}</button> -->
          <button
            *ngIf="patientRestrictedAccess"
            ngbDropdownItem
            [routerLink]="getLink('patient-notifications')"
            routerLinkActive="active"
          >
            {{ "sidebar.Notifications" | translate }}
          </button>
          <!-- <button
            ngbDropdownItem
            *ngIf="patientRestrictedAccess"
            [routerLink]="getLink('patient-rules')"
            routerLinkActive="active"
          >
            {{ "PatientRecord.PatientRules" | translate }}
          </button> -->
          <!-- <button
            ngbDropdownItem
            [routerLink]="getLink('my-doctors')"
            *ngIf="!patientRestrictedAccess"
            routerLinkActive="active"
          >
            {{ "PatientRecord.MyDoctors" | translate }}
          </button>
          <button
            ngbDropdownItem
            [routerLink]="getLink('nutrition-notifications')"
            *ngIf="patientRestrictedAccess"
            routerLinkActive="active"
            (click)="sendGoogleAnalytics('nutritional-notifications')"
          >
            {{ "PatientRecord.NutritionNotifications" | translate }}
          </button> -->
          <button ngbDropdownItem [routerLink]="getLink('history')" routerLinkActive="active">
            {{ "History" | translate }}
          </button>
          <!-- <button ngbDropdownItem [routerLink]="getLink('diabetes-diagnosis')" routerLinkActive="active">
            {{'PatientRecord.DiabetesDiagnosis'|translate}}</button> -->
        </div>
      </div>
    </div>
    <div class="record-container">
      <router-outlet></router-outlet>
    </div>
  </div>
</div>

<p-dialog
  appendTo="body"
  [header]="'InviteCaregiver' | translate"
  [(visible)]="isInviteCaregiverFormVisible"
  [modal]="true"
  [style]="{ width: '400px', 'max-width': '100%', height: 'auto' }"
>
  <app-invite-caregiver-form
    *ngIf="isInviteCaregiverFormVisible && patientId !== undefined"
    [patientId]="patientId"
    (closed)="isInviteCaregiverFormVisible = false"
  >
  </app-invite-caregiver-form>
</p-dialog>

<p-dialog
  appendTo="body"
  *ngIf="patientCareTeamPopupVisible"
  [header]="'popUpTitles.careTeam' | translate"
  [(visible)]="patientCareTeamPopupVisible"
  [modal]="true"
  positionTop="100"
  [style]="{ 'max-width': '100%', height: 'auto' }"
  [contentStyle]="{ overflow: 'visible' }"
>
  <app-patient-care-team
    [model]="patientCareTeam"
    (closed)="patientCareTeamPopupVisible = false"
    [patientId]="patientId"
  >
  </app-patient-care-team>
</p-dialog>
