import { Component, OnInit, Input } from "@angular/core";
import * as moment from "moment";
import { ToastService } from "src/app/core/toast.service";
import { CarePlanService } from "../../care-plan/care-plan.service";
import { TranslateService } from "@ngx-translate/core";
import { Observation } from "fhir-stu3";
import { IRecordItem } from "../medical-record.model";
import { ChartOptions } from "chart.js";

@Component({
  selector: "app-observation-graph",
  templateUrl: "./observation-graph.component.html",
  styleUrls: ["./observation-graph.component.scss"],
})
export class ObservationGraphComponent implements OnInit {
  @Input() model!: IRecordItem;
  @Input() patientId!: string;
  @Input() gender!: string;

  dateFormat = "YYYY-MM-DD";
  filters: {
    fromDate: Date;
    toDate: Date;
  } = {
    fromDate: moment().subtract(6, "days").toDate(),
    toDate: moment().toDate(),
  };
  minFilterDate!: Date;
  maxFilterDate = new Date();
  dataSets: any = null;
  optionsLineChart: ChartOptions = {
    responsive: true,
    animation: {
      duration: 0,
    },
  };
  colors = ["#803690", "#00ADF9", "#DCDCDC", "#46BFBD", "#FDB45C", "#949FB1", "#4D5360"];

  constructor(
    private toastService: ToastService,
    private careplanService: CarePlanService,
    private translationService: TranslateService
  ) {}

  ngOnInit() {
    this.filters = {
      fromDate: moment(this.model.date).subtract(7, "d").toDate(),
      toDate: moment().toDate(),
    };

    this.updateGraph();

    this.translationService.onLangChange.subscribe((x) => {});
  }

  applyFilters() {
    this.updateGraph();
  }

  async updateGraph() {
    try {
      const observations = await this.getObservations();
      observations.sort((a: any, b: any) => (moment(a.effectiveDateTime).isAfter(b.effectiveDateTime) ? 1 : -1));
      this.dataSets = { ...this.createDataSets(observations) };
    } catch (error) {
      this.toastService.generalErrorMessage();
    }
  }

  getObservations(): any {
    try {
      return this.careplanService
        .getCarePlanObservationsByType(
          this.patientId,
          this.model.id!,
          moment(this.filters.fromDate).format(this.dateFormat),
          moment(this.filters.toDate).clone().endOf("day").add(1, "days").format(this.dateFormat)
        )
        .toPromise();
    } catch (error) {
      this.toastService.generalErrorMessage();
    }
  }

  createDataSets(observations: Observation[]) {
    try {
      const isComposite = observations.find((x) => x.component && x.component.length > 0);
      let dataSets = this.initializeDataSets(
        isComposite
          ? isComposite.component!.map((x) => {
              return {
                code: x.code.coding?.[0].code,
                display: x.code.coding?.[0].display,
              };
            })
          : [this.model.value!.split(" ")[1]]
      );

      for (const obs of observations) {
        if (obs.component) {
          dataSets.lineGraph.labels.push(moment(obs.effectiveDateTime!).format("DD/MM HH:mm"));
          if (!obs.component) continue;
          obs.component.forEach((x) => {
            const selectedDataset = dataSets.lineGraph.datasets.find((y: any) => y.id === x.code.coding![0].code);
            selectedDataset.data.push(x.valueQuantity!.value);
          });
        } else {
          dataSets.lineGraph.labels.push(moment(obs.effectiveDateTime).format("DD/MM HH:mm"));
          dataSets.lineGraph.datasets[0].data.push(obs.valueQuantity!.value);
        }
      }

      return dataSets;
    } catch (err) {}
  }

  initializeDataSets(labels: Array<any>): any {
    let dataSets = {
      lineGraph: {
        labels: [],
        datasets: labels.map((x, idx) => ({
          label: x.display
            ? this.translationService.instant("medicalRecord.observationCode." + x.code)
            : this.translationService.instant("medicalRecord.observationCode." + x),
          id: x.code ? x.code : x,
          data: [],
          fill: false,
          borderColor: this.colors[idx],
        })),
      },
    };

    return dataSets;
  }
}
