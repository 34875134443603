import { HttpClient } from "@angular/common/http";
import { Component, OnInit, Input, Output, EventEmitter, OnDestroy } from "@angular/core";
import * as moment from "moment";
import { Subscription } from "rxjs";
import { MessageBroadcasterService } from "src/app/services/message.service";
import { environment } from "../../../../environments/environment";
import { AuthenticationService } from "../../../services/authentication.service";
import { ToastService } from "../../toast.service";
import { GarrySession } from "./streaming-examinations.model";

@Component({
  selector: "app-streaming-examinations",
  templateUrl: "./streaming-examinations.component.html",
  styleUrls: ["./streaming-examinations.component.scss"],
})
export class StreamingExaminationsComponent implements OnInit, OnDestroy {
  @Input() userId!: string;

  activeExaminationId!: string | null;
  showStreamingPopUp = false;
  newStreamingExaminationDataSubscription!: Subscription;
  endStreamingExaminationSubscription!: Subscription;

  constructor(
    private httpClient: HttpClient,
    private toastService: ToastService,
    private authService: AuthenticationService,
    private messageService: MessageBroadcasterService
  ) {}

  ngOnInit() {
    this.getOldSessions();
    this.newStreamingExaminationDataSubscription = this.messageService.newStreamingExaminationData.subscribe((x) => {
      console.log(x);
    });
    this.endStreamingExaminationSubscription = this.messageService.endStreamingExamination.subscribe((x) => {
      console.log(x);
    });
  }

  ngOnDestroy(): void {
    this.destroyActiveSession();
    this.newStreamingExaminationDataSubscription.unsubscribe();
    this.endStreamingExaminationSubscription.unsubscribe();
  }

  async getOldSessions() {
    console.log(this.userId);
    this.httpClient
      .get<GarrySession[]>(`${environment.serverPath}/api/examination-streaming/user-sessions/${this.userId}`)
      .subscribe({
        next: (data) => {
          console.log("here");
          console.log(data);
        },
        error: (error) => {
          this.toastService.generalErrorMessage();
        },
      });
  }

  startStreamingSession() {
    console.log("**////");
    console.log(this.userId);
    console.log(this.authService.getUserId());
    this.httpClient
      .post<string>(`${environment.serverPath}/api/examination-streaming/session`, {
        userId: this.userId,
        creatorId: this.authService.getUserId(),
      })
      .subscribe({
        next: (data) => {
          console.log(data);
          this.activeExaminationId = data;
          this.showStreamingPopUp = true;
        },
        error: (error) => {
          this.toastService.generalErrorMessage();
        },
      });
  }

  destroyActiveSession() {
    console.log("running destroy");
    this.activeExaminationId &&
      this.httpClient
        .delete<string>(`${environment.serverPath}/api/examination-streaming/session/${this.activeExaminationId}`)
        .subscribe({
          next: (data) => {
            this.activeExaminationId = null;
            this.showStreamingPopUp = false;
          },
          error: (error) => {
            this.toastService.generalErrorMessage();
          },
        });
  }
}
