import { Component, OnInit, Input, Output, EventEmitter, OnChanges, SimpleChanges } from "@angular/core";
import { FormGroup, FormBuilder, Validators, FormArray, FormControl } from "@angular/forms";
import { TranslateService } from "@ngx-translate/core";
import { Helpers } from "src/app/services/app.helpers";
import * as moment from "moment";

@Component({
  selector: "app-add-procedure",
  templateUrl: "./add-procedure.component.html",
  styleUrls: ["./add-procedure.component.scss"],
})
export class AddProcedureComponent implements OnInit, OnChanges {
  @Input() model: any;
  @Output() closed: EventEmitter<any> = new EventEmitter<any>();
  @Output() success: EventEmitter<any> = new EventEmitter<any>();

  form!: FormGroup;

  statusList = [
    { id: 0, name: this.translationService.instant("PatientRecord.low"), value: "preparation" },
    { id: 1, name: this.translationService.instant("PatientRecord.in-progress"), value: "in-progress" },
    { id: 2, name: this.translationService.instant("PatientRecord.suspended"), value: "suspended" },
    { id: 3, name: this.translationService.instant("PatientRecord.aborted"), value: "aborted" },
    { id: 4, name: this.translationService.instant("PatientRecord.completed"), value: "completed" },
    { id: 5, name: this.translationService.instant("PatientRecord.entered-in-error"), value: "entered-in-error" },
  ];

  constructor(private fb: FormBuilder, private translationService: TranslateService) {}

  ngOnInit() {
    this.createForm();

    this.translationService.onLangChange.subscribe((x) => {
    });
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes["model"]) this.createForm();
  }

  createForm() {
    this.form = this.fb.group({
      Status: [(this.model && this.statusList.find((x) => x.name === this.model.status)) || "", Validators.required],
      Procedure: [(this.model && this.model.code.text) || "", Validators.required],
      DatePerformed: [(this.model && moment(this.model.performedDateTime).toDate()) || "", Validators.required],
      Category: [(this.model && this.model.category.text) || "", Validators.required],
      Reason: this.fb.array([]),
      Notes: this.fb.array([]),
    });

    if (this.model && this.model.note && this.model.note.length !== 0) {
      this.model.note.forEach((note: any) => {
        (this.form.controls["Notes"] as FormArray).push(new FormControl(note.text, Validators.required));
      });
    }

    if (this.model && this.model.reasonCode && this.model.reasonCode.length !== 0) {
      this.model.reasonCode.forEach((reason: any) => {
        (this.form.controls["Reason"] as FormArray).push(new FormControl(reason.text, Validators.required));
      });
    }
  }

  onAddElement(elem: string) {
    (this.form.get(elem) as FormArray).push(new FormControl("", Validators.required));
  }

  onRemoveElement(elem: string) {
    const length = (this.form.get(elem) as FormArray).controls.length;
    (this.form.get(elem) as FormArray).removeAt(length - 1);
  }

  onClose() {
    // this.form.reset();
    // this.form.setControl("Reason", this.fb.array([]));
    // this.form.setControl("Notes", this.fb.array([]));
    this.closed.emit();
  }

  save() {
    Helpers.markFormGroupTouched(this.form);
    Helpers.markFormGroupDirty(this.form);

    if (!this.form.valid) {
      return;
    }

    let procedureModel: any = {};

    procedureModel["resourceType"] = "Procedure";
    procedureModel["status"] = this.form.controls["Status"].value.value;
    procedureModel["code"] = {
      text: this.form.controls["Procedure"].value,
    };
    procedureModel["category"] = {
      text: this.form.controls["Procedure"].value,
    };
    procedureModel["performedDateTime"] = moment(this.form.controls["DatePerformed"].value).format("YYYY-MM-DD");
    if (this.form.controls["Reason"].value.length !== 0) {
      procedureModel["reasonCode"] = this.form.controls["Reason"].value.map((x: any) => ({
        text: x,
      }));
    }
    if (this.form.controls["Notes"].value.length !== 0) {
      procedureModel["note"] = this.form.controls["Notes"].value.map((x: any) => ({
        text: x,
      }));
    }

    if (this.model) {
      delete this.model.meta;
      procedureModel = { ...this.model, ...procedureModel };
    }

    this.success.emit(procedureModel);
    this.form.reset();
    this.closed.emit();
  }
}
