import { Component, OnInit, Output, EventEmitter, Input } from "@angular/core";
import * as fhir from "fhir-stu3";
import Enumerable from "linq";
import { TranslateService } from "@ngx-translate/core";
import { AuthenticationService } from "../../../../services/authentication.service";
import { HttpClient } from "@angular/common/http";
import { environment } from "../../../../../environments/environment";
import { ToastService } from "../../../../core/toast.service";

@Component({
  selector: "app-patient-care-team",
  templateUrl: "./patient-care-team.component.html",
  styleUrls: ["./patient-care-team.component.scss"],
})
export class PatientCareTeamComponent implements OnInit {
  @Input() model!: fhir.Practitioner[];
  @Input() patientId!: string;
  @Output() closed: EventEmitter<any> = new EventEmitter<any>();

  isInvitePopupVisible = false;
  practitioners: any[] = [];
  selectedDoctorId: any;

  constructor(
    private translateService: TranslateService,
    public authService: AuthenticationService,
    private httpClient: HttpClient,
    private toastService: ToastService
  ) {}

  ngOnInit() {}

  getName(doctor: fhir.Practitioner) {
    try {
      return doctor.name![0].family + " " + doctor.name![0]!.given!.join(", ");
    } catch (err) {}
    return "";
  }

  getDoctorPosition(doctor: fhir.Practitioner) {
    try {
      const position =
        doctor.qualification?.[0]?.code?.coding?.[0].display || this.translateService.instant("General doctor");
      return position;
    } catch (err) {}
  }

  getDoctorLanguages(doctor: fhir.Practitioner) {
    try {
      const languages = Enumerable.from(doctor.communication as any)
        .select((x: any) => x.coding[0].display)
        .toArray()
        .join(", ");

      if (languages.length === 0) throw new Error();
      return this.translateService.instant("Speaks:") + " " + languages;
    } catch (err) {}

    return this.translateService.instant("Speaks:") + " " + "English";
  }

  getAddress(doctor: fhir.Practitioner) {
    try {
      const address = Enumerable.from(doctor.address as any)
        .select((x: any) => {
          let ob = "";
          if (x.line.length > 0) ob += x.line[0] + ", ";
          if (x.postalCode) ob += x.postalCode + ", ";
          if (x.city) ob += x.city + ", ";
          if (x.country) ob += x.country + ", ";

          if (ob.length < 2) return "";
          return ob.substring(0, ob.length - 2);
        })
        .first();

      if (!address) throw new Error();

      return address;
    } catch (err) {}

    return "";
  }

  getPhones(doctor: fhir.Practitioner) {
    try {
      const phones = Enumerable.from(doctor.telecom as any)
        .where((x: any) => x.system === "phone")
        .select((x: any) => x.value)
        .toArray()
        .join(", ");

      if (!phones) throw new Error();

      return phones;
    } catch (err) {}

    return "";
  }

  getEmail(doctor: fhir.Practitioner) {
    try {
      const email = Enumerable.from(doctor.telecom as any)
        .where((x: any) => x.system === "email")
        .select((x: any) => x.value)
        .first();

      if (!email) throw new Error();

      return email;
    } catch (err) {}

    return "";
  }

  close() {
    this.closed.emit();
  }

  addPractitionerToCareTeam() {
    this.isInvitePopupVisible = true;
    this.selectedDoctorId = undefined;
    this.httpClient.get<any[]>(`${environment.serverPath}/api/doctors/lookup`).subscribe((res) => {
      this.practitioners = res;
      const existingConsents: any[] = this.model.map((x) => x.id);
      this.practitioners = this.practitioners.filter((x) => !existingConsents.includes(x.id));
    });
  }

  savePractitionerToCareTeam() {
    if (!this.selectedDoctorId) return;

    this.httpClient
      .post<any>(`${environment.serverPath}/api/doctors/create-consent-request`, {
        practitionerFhirId: this.selectedDoctorId,
        patientId: this.patientId,
      })
      .subscribe((res) => {
        this.toastService.successMessage(
          "A consent request has been sent to the patient for the selected practitioner"
        );
        setTimeout(() => {
          this.selectedDoctorId = undefined;
          this.closed.emit();
        });
      });
  }

  selectPractitioner(doctorId: any) {
    this.selectedDoctorId = doctorId;
  }

  removeConsent(doctorId: any) {
    this.toastService.confirmDeleteWithMessage("Are you sure you want to revoke consent?", () => {
      this.httpClient
        .post<any>(`${environment.serverPath}/api/doctors/revoke-consent-request`, {
          practitionerFhirId: doctorId,
          patientId: this.patientId,
        })
        .subscribe((res) => {
          this.toastService.successMessage("Consent has been revoked");
          setTimeout(() => {
            this.closed.emit();
          });
        });
    });
  }
}
