import { Injectable, EventEmitter } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { environment } from "src/environments/environment";
import { PatientDTO, ICreateActivityDTO } from "../models/patient.model";
import * as moment from "moment";
import { DiagnosisDTO } from "../models/diagnosis.models";
import * as fhir from "fhir-stu3";
import { Helpers } from "./app.helpers";
import { ICarePlan } from "../pages/patient-record/care-plan/careplan.dtos";
import { TimelineItem } from "../core/components/horizontal-timeline/timeline.model";
import { IConsentDTO } from "../models/consent.model";
import { PeriodTypes } from "../core/enum/PeriodTypes";

@Injectable({
  providedIn: "root",
})
export class PatientsService {
  selectedPatients!: Array<any>;

  compareSelectedPatientsChanged = new EventEmitter<Array<any>>();

  disableAnalyticsMenus = new EventEmitter<boolean>();

  constructor(private http: HttpClient, private helpers: Helpers) {}

  getSelectedPatients() {
    return this.selectedPatients;
  }

  setSelectedPatients(patients: Array<any>) {
    this.selectedPatients = patients;
    this.compareSelectedPatientsChanged.emit(this.selectedPatients);
  }

  public getPatientMeasurementsForDates(
    fromDate: Date,
    toDate: Date,
    patients: Array<string>,
    measurements: Array<string>
  ) {
    return this.http.post<Array<any>>(`${environment.serverPath}/api/doctors/patient-measurements`, {
      fromDate,
      toDate,
      patients,
      measurements,
    });
  }

  public getCriticalEvents(patientId: string) {
    return this.http.get<Array<TimelineItem>>(
      `${environment.serverPath}/api/doctors/patient-critical-events/get/${patientId}`
    );
  }

  public getAll() {
    return this.http.get<Array<fhir.Patient>>(`${environment.serverPath}/api/patient`);
  }

  public getAllWithoutConsent() {
    return this.http.get<Array<fhir.Patient>>(`${environment.serverPath}/api/patient/without-consent`);
  }

  public getProposedRequestConsents() {
    return this.http.get<Array<fhir.Patient>>(`${environment.serverPath}/api/doctors/proposed-requests`);
  }

  public getPatientInfo(patientId: string) {
    return this.http.get<fhir.Patient>(`${environment.serverPath}/api/patient/info/${patientId}`);
  }

  public updatePatientInfo(model: PatientDTO) {
    return this.http.put<fhir.Patient>(`${environment.serverPath}/api/patient/info/${model.Id}`, model);
  }

  public addDrug(patientId: string, model: ICreateActivityDTO) {
    return this.http.post(`${environment.serverPath}/api/patient-record/careplan/drug/${patientId}`, model);
  }

  public addObservation(patientId: string, model: ICreateActivityDTO) {
    return this.http.post(`${environment.serverPath}/api/patient-record/careplan/observation/${patientId}`, model);
  }

  public addNutrition(patientId: string, model: ICreateActivityDTO) {
    return this.http.post(`${environment.serverPath}/api/patient-record/careplan/nutrition/${patientId}`, model);
  }

  public addEducation(patientId: string, model: ICreateActivityDTO) {
    return this.http.post(`${environment.serverPath}/api/patient-record/careplan/education/${patientId}`, model);
  }

  public addLaboratory(patientId: string, model: ICreateActivityDTO) {
    return this.http.post(`${environment.serverPath}/api/patient-record/careplan/laboratory/${patientId}`, model);
  }

  public addAppointment(patientId: string, model: ICreateActivityDTO) {
    return this.http.post(`${environment.serverPath}/api/patient-record/careplan/appointment/${patientId}`, model);
  }

  public addExercise(patientId: string, model: ICreateActivityDTO) {
    return this.http.post(`${environment.serverPath}/api/patient-record/careplan/exercise/${patientId}`, model);
  }

  public addQuestionnaire(patientId: string, model: ICreateActivityDTO) {
    return this.http.post(`${environment.serverPath}/api/patient-record/careplan/questionnaire/${patientId}`, model);
  }

  public getCarePlanActivities(patientId: string) {
    return this.http.get<Array<ICarePlan>>(
      `${environment.serverPath}/api/patient-record/careplan/activities/${patientId}`
    );
  }

  public getCarePlanCalendarActivities(patientId: string) {
    return this.http.get<Array<ICarePlan>>(
      `${environment.serverPath}/api/patient-record/careplan/calendar/${patientId}`
    );
  }

  public requestConsent(fromDate: Date, toDate: Date, patientId: string) {
    return this.http.post(`${environment.serverPath}/api/consent/request-consent`, {
      FromDate: fromDate,
      ToDate: toDate,
      PatientId: patientId,
    });
  }

  public rejectConsent(consentId: string) {
    return this.http.delete(`${environment.serverPath}/api/consent/reject-consent/${consentId}`);
  }

  public removeConsent(consentId: string) {
    return this.http.delete(`${environment.serverPath}/api/consent/remove-consent/${consentId}`);
  }

  public markCriticalEventAsRead(id: number) {
    return this.http.post(`${environment.serverPath}/api/doctors/patient-critical-events/mark-read`, { id });
  }

  //#region Helpers
  public conditionToDiagnosisDTO(condition: fhir.Condition) {
    if (!condition) return undefined;

    const diagnosis: DiagnosisDTO = {};
    diagnosis.id = condition.id;
    diagnosis.status = condition.clinicalStatus;
    diagnosis.modifiedDate = moment(condition.meta!.lastUpdated).toDate();

    if (condition.code!.coding && condition.code!.coding.length > 0) {
      diagnosis.conditionText = this.helpers.getCurrentOrDefaultTranslationFromFhirExtension(condition.code!.coding[0]);
    }

    if (
      condition.bodySite &&
      condition.bodySite.length > 0 &&
      condition.bodySite[0].coding &&
      condition.bodySite[0].coding.length > 0
    ) {
      diagnosis.bodySite = this.helpers.getCurrentOrDefaultTranslationFromFhirExtension(
        condition.bodySite[0].coding[0]
      );
    }

    if (condition.assertedDate) {
      diagnosis.recordedDate = moment(condition.assertedDate, "YYYY-MM-DD").toDate();
    }

    if (condition.asserter) {
      diagnosis.recorder = condition.asserter.display;
    }

    return diagnosis;
  }

  public FhirPatientToPatientDTO(fhirPatient: fhir.Patient) {
    if (!fhirPatient) {
      return null;
    }
    const patient: PatientDTO = {};
    patient.Id = fhirPatient.id;
    patient.userId = (fhirPatient as any).userId;
    patient.Firstname = fhirPatient.name ? fhirPatient!.name?.[0].given?.[0] : "";
    patient.Lastname = fhirPatient.name ? fhirPatient.name[0].family : "";
    patient.Fullname = patient.Firstname + " " + patient.Lastname;
    patient.ConsentExpires = (fhirPatient as any).consentExpires
      ? moment((fhirPatient as any).consentExpires).toDate()
      : undefined;
    patient.ConsentId = (fhirPatient as any).consentId ? (fhirPatient as any).consentId : "";
    patient.consentRequestedBy = (fhirPatient as any).consentRequestedBy ? (fhirPatient as any).consentRequestedBy : "";
    patient.CitizenNumber = (fhirPatient as any).citizenNumber;
    patient.Gender = (fhirPatient as any).gender ? (fhirPatient as any).gender : undefined;
    // patient.CreatedOn = new Date(fhirPatient.meta.extension.find(x => x.url === "resources:created-date").valueDate);
    patient.ModifiedOn = new Date(fhirPatient.meta!.lastUpdated!);
    patient.MaggicRisk = (fhirPatient as any).maggicRisk !== undefined ? (fhirPatient as any).maggicRisk : "";
    patient.Risk1Year = (fhirPatient as any).risk1Year !== undefined ? (fhirPatient as any).risk1Year + "%" : "";
    patient.Risk3Year = (fhirPatient as any).risk3Year !== undefined ? (fhirPatient as any).risk3Year + "%" : "";
    patient.comorbidities = (fhirPatient as any).comorbidities;
    patient.comorbiditiesOb = (fhirPatient as any).comorbiditiesObs;
    patient.mainConditions = (fhirPatient as any).patientMainConditions;
    patient.updatedAt = (fhirPatient as any).updatedAt;

    if (fhirPatient.birthDate) {
      patient.DateOfBirth = fhirPatient.birthDate;

      const age = moment().diff(moment(patient.DateOfBirth, "YYYY-MM-DD"), "years");
      patient.Age = !isNaN(age) ? age.toString() : "";
    }

    if (fhirPatient.address && fhirPatient.address.length > 0) {
      if (fhirPatient.address[0].line && fhirPatient.address[0].line.length) {
        patient.Address =
          (fhirPatient.address[0].line[0] ? fhirPatient.address[0].line[0] : "") +
          (fhirPatient.address[0].postalCode ? ", " + fhirPatient.address[0].postalCode : "") +
          (fhirPatient.address[0].city ? ", " + fhirPatient.address[0].city : "");
      } else if (fhirPatient.address[0].text) {
        patient.Address = fhirPatient.address[0].text;
      }
    }

    if (fhirPatient.telecom) {
      const phones: string[] = [];
      fhirPatient.telecom.forEach((x) => {
        if (x.system === "email") {
          patient.Email = x.value;
        } else if (x.system === "phone" && x.use === "home") {
          if (!patient.Phone) patient.Phone = "";

          phones.push(x.value as string);
        } else if (x.system === "phone" && x.use === "work") {
          if (!patient.Phone) patient.Phone = "";

          phones.push(x.value as string);
        }
      });
      patient.Phone = phones.join("<br/>");
    }

    if (fhirPatient.identifier && fhirPatient.identifier.find((x) => x.system === "SimpleCode")) {
      patient.Codes = fhirPatient.identifier?.find((x) => x.system === "SimpleCode")?.value;
    }

    if (fhirPatient.extension && fhirPatient.extension.find((x) => x.url === "case-manager")) {
      patient.CaseManager = fhirPatient.extension
        ?.find((x: any) => x.url === "case-manager")
        ?.valueReference?.reference?.replace("Practitioner/", "");
      patient.CaseManagerName = fhirPatient.extension.find((x) => x.url === "case-manager")?.valueReference?.display;
    }

    if (fhirPatient.extension && fhirPatient.extension.find((x) => x.url === "http://coral-extensions/33762-6")) {
      patient.ntProBnp = fhirPatient.extension?.find(
        (x: any) => x.url === "http://coral-extensions/33762-6"
      )?.valueDecimal;
    }

    if (
      fhirPatient.extension &&
      fhirPatient.extension.find((x) => x.url === "http://coral-extensions/nyhaClassification")
    ) {
      patient.nyhaClassification = fhirPatient.extension?.find(
        (x: any) => x.url === "http://coral-extensions/nyhaClassification"
      )?.valueDecimal;
    }

    if (fhirPatient.identifier?.find((x) => x.type?.coding?.[0]?.code === "cia")) {
      patient.cia = fhirPatient.identifier?.find((x) => x.type?.coding?.[0]?.code === "cia")?.value;
    }

    patient.CriticalEvents = (fhirPatient as any).criticalEvents ? (fhirPatient as any).criticalEvents.length : 0;
    patient.CriticalEventsList = (fhirPatient as any).criticalEvents || [];

    patient.observations = (fhirPatient as any).observations;

    return patient;
  }
  //#endregion

  public getPatientPendingConsents() {
    return this.http.get<Array<IConsentDTO>>(`${environment.serverPath}/api/patient/pending-patient-consents`);
  }

  public acceptConsent(consentId: string) {
    return this.http.post(`${environment.serverPath}/api/consent/accept-consent`, { consentId });
  }

  public getPatientsLastMeasurement(patients: any, measurements: any) {
    return this.http.post<Array<any>>(`${environment.serverPath}/api/doctors/patients-last-measurement`, {
      patients,
      measurements,
    });
  }

  public getPatientsMedianValuesForObservation(model: {
    patientIds: Array<number>;
    code: string;
    period: PeriodTypes;
    from: string;
    to: string;
  }) {
    return this.http.post<Array<any>>(`${environment.serverPath}/api/analytics/median`, { ...model });
  }

  public getPatientsMedianValuesForObservationsForPeriod(model: {
    fromDate: Date;
    toDate: Date;
    patients: Array<string>;
    measurements: Array<string>;
  }) {
    return this.http.post<{ [patientId: string]: Array<{ category: string; value: number }> }>(
      `${environment.serverPath}/api/analytics/median-for-period`,
      { ...model }
    );
  }
}
