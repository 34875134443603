import { Injectable } from "@angular/core";

import { Resolve } from "@angular/router";

import { ActivatedRouteSnapshot } from "@angular/router";
import { PatientsService } from "src/app/services/patients.service";
import { PatientRecordService } from "./patient-record.service";
import { Role } from "src/app/core/enum/Role";
import { AuthenticationService } from "src/app/services/authentication.service";

@Injectable({
  providedIn: "root",
})
export class PatientRecordResolver implements Resolve<any> {
  patientRestrictedAccess!: boolean;

  constructor(
    private patientService: PatientsService,
    private patientRecordService: PatientRecordService,
    private authenticationService: AuthenticationService
  ) {}

  async resolve(route: ActivatedRouteSnapshot) {
    let patientInfo: any;
    this.patientRestrictedAccess =
      this.authenticationService.hasRole(Role.Doctor) || this.authenticationService.hasRole(Role.CareCoordinator);

    if (this.patientRestrictedAccess) {
      patientInfo = await this.patientService.getPatientInfo(route.params["patientId"]).toPromise();
    } else {
      patientInfo = await this.patientService.getPatientInfo(this.authenticationService.getUser().fhirId).toPromise();
    }

    this.patientRecordService.patientInfo = this.patientService.FhirPatientToPatientDTO(patientInfo!) as any;

    if (this.patientRestrictedAccess) {
      this.patientRecordService.wizardCompleted = patientInfo.wizardCompleted;
    } else {
      this.patientRecordService.wizardCompleted = true;
    }

    return this.patientRecordService.patientInfo;
  }
}
