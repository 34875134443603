import { TranslationModel } from "./translation.model";

export class EntityValueSetTranslatable {
  Id = 0;
  Code = "";
  Name: Array<TranslationModel> = [];
}

export class EntityValueSet {
  Id = 0;
  Code = "";
  Name = "";
}

export const observationToCategoryMapping: Array<{ category: string; observation: string; unit?: string }> = [
  { category: "calories_active", observation: "calories_active", unit: "kcal" },
  { category: "spo2", observation: "59408-5", unit: "%" },
  { category: "33762-6", observation: "33762-6", unit: "pg/mL" },
  { category: "nyhaClassification", observation: "nyhaClassification" },
  { category: "general.age", observation: "age", unit: "years" },
  { category: "general.gender", observation: "gender", unit: "" },
  { category: "general.waiste_circumference", observation: "waiste_circumference", unit: "cm" },
  { category: "stress", observation: "stress-level-average", unit: "" },
  { category: "heart-rate", observation: "8867-4", unit: "bpm" },
  { category: "heart-rate-max", observation: "8873-2", unit: "bpm" },
  { category: "heart-rate-min", observation: "heart-rate-min", unit: "bpm" },
  { category: "heart-rate-manual", observation: "heart-rate-avg-manual", unit: "bpm" },
  { category: "general.body_measure", observation: "body_measure", unit: "" },
  { category: "height", observation: "8302-2", unit: "m" },
  { category: "3141-9", observation: "3141-9", unit: "kgr" },
  { category: "LP35925-4", observation: "LP35925-4", unit: "Kgr/m2" },
  { category: "3141-9-manual", observation: "3141-9-manual", unit: "kgr" },
  { category: "general.body_fat", observation: "body_fat", unit: "Kgr" },
  { category: "general.body_fat_percent", observation: "body_fat_percent", unit: "%" },
  { category: "calories", observation: "calories-active", unit: "kcal" },
  { category: "calories-manual", observation: "calories-active-manual", unit: "kcal" },
  { category: "calories", observation: "calories-resting", unit: "kcal" },
  { category: "calories-manual", observation: "calories-resting-manual", unit: "kcal" },
  { category: "general.body_muscle", observation: "body_muscle", unit: "Kgr" },
  { category: "general.body_muscle_percent", observation: "body_muscle_percent", unit: "Kgr" },
  { category: "general.body_composition", observation: "body_composition", unit: "" },
  { category: "general.temperature", observation: "8310-5", unit: "°C" },
  { category: "general.sleep", observation: "93832-4", unit: "hours" },
  { category: "general.steps", observation: "41950-7", unit: "steps" },
  { category: "15074-8", observation: "99504-3", unit: "mg/dL" },
  { category: "15074-8-manual", observation: "2339-0", unit: "mg/dL" },
  { category: "blood.hba1c", observation: "4548-4", unit: "%" },
  { category: "55284-4", observation: "8462-4", unit: "mmHg" },
  { category: "55284-4-manual", observation: "8462-4-manual", unit: "mmHg" },
  { category: "55284-4", observation: "8480-6", unit: "mmHg" },
  { category: "55284-4-manual", observation: "8480-6-manual", unit: "mmHg" },
  { category: "blood.creatinine", observation: "2160-0", unit: "mg/dL" },
  { category: "blood.urea_level", observation: "3092-4", unit: "mg/dL" },
  { category: "blood.albuminuria", observation: "14957-5", unit: "mg/dL" },
  { category: "blood.glycate_hemoglobin", observation: "glycate_hemoglobin", unit: "mmol/mol" },
  { category: "blood.total_cholesterol", observation: "2039-3", unit: "mg/dL" },
  { category: "blood.triglycerides", observation: "2571-8", unit: "mg/dL" },
  { category: "ldl_cholesterol", observation: "13457-7", unit: "mg/dL" },
  { category: "blood.creatinine_renal_clearance", observation: "2164-2", unit: "mmol/mol" },
  { category: "blood.total_hemoglobin_concentration", observation: "718-7", unit: "mmol/mol" },
  { category: "blood.wbc", observation: "26464-8", unit: "10^3/μL" },
  { category: "bmi", observation: "bmi", unit: "Kgr/m2" },
  { category: "plt", observation: "26515-7", unit: "x1000/µL" },
  { category: "ferritin", observation: "24373-3", unit: "ng/mL" },
  { category: "ferrum", observation: "2498-4", unit: "ng/mL" },
  { category: "uric-acid", observation: "3084-1", unit: "mg/dL" },
  { category: "hdl-cholesterol", observation: "2085-9", unit: "mg/dL" },
  { category: "alkaline-phosphatase", observation: "6768-6", unit: "µkat/L" },
  { category: "crp", observation: "30522-7", unit: "μg/mL" },
  { category: "33762-6", observation: "33762-6", unit: "pg/mL" },
  { category: "cpk", observation: "2157-6", unit: "units/L" },
  { category: "ggt", observation: "2324-2", unit: "µkat/L" },
  { category: "album", observation: "album", unit: "g/dL" },
  { category: "albumin", observation: "1754-1", unit: "g/dL" },
  { category: "ptinr", observation: "5902-2", unit: "s" },
  { category: "sgot", observation: "1920-8", unit: "IU/L" },
  { category: "sgpt", observation: "1742-6", unit: "IU/L" },
  { category: "potassium", observation: "6298-4", unit: "mmol/L" },
  { category: "sodium", observation: "2947-0", unit: "mmol/L" },
  { category: "tbw", observation: "tbw", unit: "Kg" },
  { category: "bmr", observation: "bmr", unit: "Kcal" },
  { category: "visceral_fat", observation: "visceral_fat", unit: "" },
  { category: "skeletal_muscles", observation: "skeletal_muscles", unit: "" },
  { category: "bone_mass", observation: "bone_mass", unit: "kgr" },
  { category: "bfmi", observation: "bfmi", unit: "kgr/m2" },
  { category: "ffmi", observation: "ffmi", unit: "kgr/m2" },
  { category: "hct", observation: "20570-8", unit: "%" },
  { category: "rbc", observation: "789-8", unit: "10^3/μL" },
  { category: "tbw", observation: "tbw", unit: "Kgr" },
  { category: "tbw_percent", observation: "tbw_percent", unit: "%" },
  { category: "calories_active", observation: "calories_active", unit: "kcal" },
  { category: "calories_active-manual", observation: "calories_active-manual", unit: "kcal" },
  { category: "calories_resting", observation: "calories_resting", unit: "kcal" },
  { category: "calories_resting-manual", observation: "calories_resting-manual", unit: "kcal" },
  { category: "distance", observation: "41953-1", unit: "m" },
  { category: "floors", observation: "floors-climbed", unit: "" },
  { category: "nyhaClassification", observation: "420816009", unit: "" },
  { category: "AISScore", observation: "457981000124103", unit: "" },
  { category: "GlobalDeteriorationScale", observation: "774093002", unit: "" },
  { category: "RespiratoryRate", observation: "9279-1", unit: "" },
  { category: "InhalerUse", observation: "704098003", unit: "" },
  { category: "FEV1/FVC", observation: "19926-5", unit: "" },
  { category: "FEV1", observation: "20150-9", unit: "" },
  { category: "PEF", observation: "33452-4", unit: "" },
  { category: "TidalVolume", observation: "76221-1", unit: "" },
  { category: "RespiratoryRate", observation: "9279-1", unit: "" },
  { category: "TLC", observation: "19862-2", unit: "" },
  { category: "DLCO", observation: "19911-7", unit: "" },
  { category: "MVV", observation: "20159-0", unit: "" },
  { category: "ntprobnp", observation: "33762-6", unit: "" },
  { category: "Chloride", observation: "2069-3", unit: "mmol/L" },
  { category: "CarbonDioxide", observation: "20565-8", unit: "mmol/L" },
  { category: "AnionGap", observation: "41276-7", unit: "mmol/L" },
  { category: "Eosinophils", observation: "26449-9", unit: "#/volume" },
  { category: "Alpha1Antitrypsin", observation: "1825-9", unit: "mass/volume" },
  { category: "PaO2(arterial)", observation: "2703-7", unit: "mmHg" },
  { category: "SaO2(arterial)", observation: "2708-6", unit: "%" },
  { category: "pCO2(arterial)", observation: "2019-8", unit: "" },
  { category: "pH(arterial)", observation: "2744-1", unit: "" },
];
