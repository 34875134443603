import { Component, OnInit, OnDestroy, HostListener } from "@angular/core";
import { ThemeService } from "../theme.service";
import { trigger, transition, animate, style, state } from "@angular/animations";
import { menuItem } from "src/app/services/app.constants";
import { menuItems } from "src/app/services/app.menu";
import { AuthenticationService } from "src/app/services/authentication.service";
import { Router } from "@angular/router";
import { GoogleAnalyticsService } from "src/app/services/google-analytics.service";
import { Role } from "src/app/core/enum/Role";

@Component({
  // tslint:disable-next-line: component-selector
  selector: "iti-sidebar",
  styleUrls: ["./sidebar.component.scss"],
  templateUrl: "./sidebar.component.html",
  animations: [
    trigger("slideInOut", [
      state(
        "in",
        style({
          width: "250px",
        })
      ),
      state(
        "out",
        style({
          width: "0px",
        })
      ),
      transition("in => out", [animate("200ms ease-in")]),
      transition("out => in", [animate("200ms ease-in")]),
    ]),
  ],
})
export class SidebarComponent implements OnInit, OnDestroy {
  menu: Array<menuItem> = menuItems.filter((x) => x.sidebarMenuItem);
  userRoles: Array<any> = [];
  userRole = "";

  constructor(
    private themeService: ThemeService,
    private authService: AuthenticationService,
    private router: Router,
    private googleAnalyticsService: GoogleAnalyticsService
  ) {}

  get isOpen() {
    return this.themeService.animationState;
  }

  ngOnDestroy(): void {}
  ngOnInit(): void {
    this.userRoles = this.authService.getUserRoles();
    this.userRole = this.authService.getUserRoles()?.[0];
    this.menu = this.menu.filter((menuItem) => {
      if (menuItem.roles && !menuItem.roles.some((role) => this.userRoles.includes(role))) {
        return false;
      }
      return true;
    });

    if (window.innerWidth < 768) {
      this.themeService.closeMenu();
      this.themeService.setMobile(true);
    }
  }

  @HostListener("window:resize", ["$event"])
  onResize(event: any) {
    if (event.target.innerWidth < 768) {
      this.themeService.closeMenu();
      this.themeService.setMobile(true);
    } else {
      this.themeService.openMenu();
      this.themeService.setMobile(false);
    }
  }

  toggleMenu(): void {
    this.themeService.toggleMenu();
  }

  logoutUser() {
    this.authService.logout();
    this.router.navigateByUrl("/login");
  }

  sendGoogleAnalytics(link: string | undefined) {
    if (link === "/nutrition-record-diary" && this.userRoles.includes(Role.Patient)) {
      this.googleAnalyticsService.eventEmitter("view_nutrition_diary_patient", "engagement", "patient");
    }
    if (link === "/notifications" && this.userRoles.includes(Role.Patient)) {
      this.googleAnalyticsService.eventEmitter("view_notifications_patient", "engagement", "patient");
    }
    if (link === "/education" && this.userRoles.includes(Role.Patient)) {
      this.googleAnalyticsService.eventEmitter("view_education_patient", "engagement", "patient");
    }
  }
}
