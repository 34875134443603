import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { StreamingExaminationsComponent } from "./streaming-examinations.component";
import { TranslateModule } from "@ngx-translate/core";
import { ButtonModule } from "primeng/button";
import { DialogModule } from "primeng/dialog";
import { CardModule } from "primeng/card";

@NgModule({
  declarations: [StreamingExaminationsComponent],
  imports: [CommonModule, TranslateModule, DialogModule, ButtonModule, CardModule],
  exports: [StreamingExaminationsComponent],
})
export class StreamingExaminationsModule {}
