import { Component, OnInit, Input, Output, EventEmitter, SimpleChanges, OnChanges } from "@angular/core";
import { FormGroup, FormBuilder, Validators, FormArray, FormControl } from "@angular/forms";
import { TranslateService } from "@ngx-translate/core";
import { Helpers } from "src/app/services/app.helpers";
import * as moment from "moment";
import { HttpClient } from "@angular/common/http";
import { environment } from "../../../../../environments/environment";
import { ChartOptions } from "chart.js";
import { ApplicationScoresService, TactusApps, TactusRecord } from "./application-scores.service";
import { ToastService } from "src/app/core/toast.service";

@Component({
  selector: "app-application-scores",
  templateUrl: "./application-scores.component.html",
  styleUrls: ["./application-scores.component.scss"],
})
export class ApplicationScoresComponent implements OnInit, OnChanges {
  @Input() patientId: any;
  @Output() closed: EventEmitter<any> = new EventEmitter<any>();
  @Output() success: EventEmitter<any> = new EventEmitter<any>();

  applicationScores: any[] = [];
  showApplicationsChart = false;
  applicationsScoresDataSet: any;
  optionsLineChart: ChartOptions = {
    responsive: true,
    animation: {
      duration: 0,
    },
  };

  tactusData: TactusRecord[][] = [];
  showTactusForm = false;
  tactusForm!: FormGroup;
  tactusApps = (Object.keys(TactusApps) as Array<keyof typeof TactusApps>)
    .filter((x) => Number.isNaN(+x))
    .map((x) => {
      return {
        id: TactusApps[x],
        name: x,
        label: this.translationService.instant(x),
      };
    });
  formSubmitting = false;
  tactusItemToEdit!: TactusRecord | null;

  constructor(
    private fb: FormBuilder,
    private translationService: TranslateService,
    private httpClient: HttpClient,
    private applicationScoresService: ApplicationScoresService,
    private toastService: ToastService
  ) {}

  // TODO: toast service

  ngOnInit() {
    this.getPatientLeaderBoard();
    this.getTactusData();

    this.translationService.onLangChange.subscribe((x) => {});
  }

  async getTactusData() {
    this.applicationScoresService.getAllTactusRecords(this.patientId).subscribe({
      next: (data) => {
        this.tactusData = (Object.keys(TactusApps) as Array<keyof typeof TactusApps>)
          .filter((x) => Number.isNaN(+x))
          .map((x) => {
            return data.filter((y) => y.app === TactusApps[x]);
          });
      },
      error: (err) => this.toastService.errorMessage(`Server error: ${err}`),
    });
  }

  getPatientLeaderBoard() {
    this.httpClient
      .get<any>(`${environment.serverPath}/api/pointing-system/get-patient-leaderboard?patientId=${this.patientId}`)
      .subscribe((res) => {
        this.applicationScores = [];
        this.applicationsScoresDataSet = { labels: [] };
        const points: any[] = [];
        for (const item of res) {
          for (const subPoints of item.subpoints) {
            let selectedGame = points.find((z) => z.id === subPoints.gameId);
            if (!selectedGame) {
              selectedGame = { id: subPoints.gameId, item: subPoints.catalogItem, points: 0, pointsAnalysis: [] };
              points.push(selectedGame);
            }

            selectedGame.points = subPoints.points;
            selectedGame.pointsAnalysis.push({ date: new Date(subPoints.createdAt), points: subPoints.points });
          }
        }
        this.applicationScores = points;
      });
  }

  openApplicationsScoreChart(item: any) {
    this.showApplicationsChart = true;
    this.applicationsScoresDataSet = { labels: [], datasets: [{ data: [], fill: false, label: item.item.name }] };
    for (const p of item.pointsAnalysis) {
      this.applicationsScoresDataSet.labels.push(moment(p.date!).format("DD/MM HH:mm"));
      this.applicationsScoresDataSet.datasets[0].data.push(p.points);
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes["model"]) this.createForm();
  }

  addTactusRecord() {
    this.createForm();
    this.showTactusForm = true;
  }

  createForm() {
    this.tactusForm = this.fb.group({
      app: [
        (this.tactusItemToEdit &&
          this.tactusItemToEdit.app &&
          this.tactusApps.find((x) => x.id === this.tactusItemToEdit?.app)?.id) ||
          null,
        Validators.required,
      ],
      date: [(this.tactusItemToEdit && moment(this.tactusItemToEdit.date).toDate()) || null, Validators.required],
      data: [(this.tactusItemToEdit && this.tactusItemToEdit.data) || null, Validators.required],
    });
  }

  onClose() {
    // this.form.reset();
  }

  saveTactusForm() {
    Helpers.markFormGroupTouched(this.tactusForm);
    Helpers.markFormGroupDirty(this.tactusForm);
    if (!this.tactusForm.valid) {
      return;
    }

    const model = this.tactusForm.getRawValue();

    if (this.tactusItemToEdit) {
      this.applicationScoresService.editTactusRecord(this.tactusItemToEdit.id, model).subscribe({
        next: (value) => {
          this.toastService.infoMessage(this.translationService.instant("alerts.SAVE_SUCCESS"));
          this.tactusItemToEdit = null;
          this.tactusForm.reset();
          this.showTactusForm = false;
          this.getTactusData();
        },
        error: (err) => {
          this.tactusItemToEdit = null;
          this.tactusForm.reset();
          this.showTactusForm = false;
          this.toastService.errorMessage(`Server error: ${err}`);
        },
      });
    } else {
      this.applicationScoresService.addTactusRecord(this.patientId, model).subscribe({
        next: (value) => {
          this.toastService.infoMessage(this.translationService.instant("alerts.SAVE_SUCCESS"));
          this.tactusForm.reset();
          this.showTactusForm = false;
          this.getTactusData();
        },
        error: (err) => {
          this.tactusForm.reset();
          this.showTactusForm = false;
          this.toastService.errorMessage(`Server error: ${err}`);
        },
      });
    }
  }

  editItem(item: TactusRecord) {
    this.tactusItemToEdit = item;
    this.createForm();
    this.showTactusForm = true;
  }

  deleteItem(item: TactusRecord) {
    this.toastService.confirmDelete(() => {
      this.applicationScoresService.deleteTactusRecord(item.id).subscribe({
        next: (value) => {
          this.toastService.infoMessage(this.translationService.instant("alerts.DELETE_SUCCESS"));
          this.getTactusData();
        },
        error: (err) => this.toastService.errorMessage(`Server error: ${err}`),
      });
    });
  }
}
