import { Component, OnInit, Input, OnChanges, SimpleChanges, Output, EventEmitter } from "@angular/core";
import * as moment from "moment";
import { TimelineItem } from "./timeline.model";
import { TranslateService } from "@ngx-translate/core";
import { TactusRecord } from "../application-scores.service";

@Component({
  selector: "app-tactus-timeline",
  templateUrl: "./tactus-timeline.component.html",
  styleUrls: ["./tactus-timeline.component.scss"],
})
export class TactusTimelineComponent implements OnInit {
  _items: Array<TimelineItem> = [];
  _allItems: Array<TimelineItem> = [];
  selectedDayRange: "year" | "week" | "today" = "year";
  selectedNotificationStatus: string[] = [];
  selectedNotificationType: string[] = [];
  types: Array<{ type: string; color: string }> = [];

  momentUnits = {
    year: "years" as moment.unitOfTime.DurationConstructor,
    week: "weeks" as moment.unitOfTime.DurationConstructor,
    today: "days" as moment.unitOfTime.DurationConstructor,
  };

  @Output() markAsRead: EventEmitter<TimelineItem> = new EventEmitter<TimelineItem>();
  @Output() editItem: EventEmitter<TactusRecord> = new EventEmitter<TactusRecord>();
  @Output() deleteItem: EventEmitter<TactusRecord> = new EventEmitter<TactusRecord>();

  @Input()
  get items() {
    return this._items;
  }
  set items(value: Array<TimelineItem>) {
    value.forEach((x) => {
      if (!x.color) x.color = "black";
      if (!this.types.find((z) => z.type === x.type)) {
        this.types.push({ type: x.type!, color: x.color });
      }
    });

    value.sort((a, b) => new Date(b.date).getTime() - new Date(a.date).getTime());

    this._items = value;
    this._allItems = value;
    this.selectedNotificationStatus = ["unread"];
    this.selectedNotificationType = ["all"];
    this.updateFiltered();
  }

  constructor(private translationService: TranslateService) {}

  ngOnInit() {}

  formatDate(date: Date, format: string) {
    return moment(date).format(format);
  }

  updateStatusAll() {
    if (this.selectedNotificationStatus.includes("all") && this.selectedNotificationStatus.length > 1) {
      this.selectedNotificationStatus = [];
      this.selectedNotificationStatus.push("all");
    }
    this.updateFiltered();
  }

  updateTypesAll() {
    if (this.selectedNotificationType.includes("all") && this.selectedNotificationType.length > 1) {
      this.selectedNotificationType = [];
      this.selectedNotificationType.push("all");
    }
    this.updateFiltered();
  }

  markAsReadClick(item: TimelineItem) {
    this.markAsRead.emit(item);
  }

  updateFiltered() {
    this._items = this._allItems;
    const selectedTimePeriod = this.momentUnits[this.selectedDayRange];
    const now = moment();

    if (this.selectedNotificationStatus.length === 0) {
      this.selectedNotificationStatus.push("all");
    }

    if (this.selectedNotificationType.length === 0) {
      this.selectedNotificationType.push("all");
    }

    if (this.selectedNotificationStatus.includes("all") && this.selectedNotificationStatus.length > 1) {
      this.selectedNotificationStatus.splice(
        this.selectedNotificationStatus.findIndex((x) => x === "all"),
        1
      );
    }

    if (this.selectedNotificationType.includes("all") && this.selectedNotificationType.length > 1) {
      this.selectedNotificationType.splice(
        this.selectedNotificationType.findIndex((x) => x === "all"),
        1
      );
    }

    this._items = this._allItems.filter((x) => {
      let flag = true;

      if (!this.selectedNotificationStatus.includes("all")) {
        flag = this.selectedNotificationStatus.includes(x.isRead ? "read" : "unread");
      }

      if (!this.selectedNotificationType.includes("all") && flag) {
        flag = this.selectedNotificationType.includes(x.type!);
      }

      if (flag) {
        flag = moment(x.date).isAfter(now.subtract(1, selectedTimePeriod));
      }

      return flag;
    });

    this._items.sort((a, b) => {
      if (a.date < b.date) return 1;
      if (a.date > b.date) return -1;
      return 0;
    });
  }

  getNumberOfRows(str: string) {
    return str.split(/\r\n|\r|\n/).length;
  }

  editItemClick(item: TactusRecord) {
    this.editItem.emit(item);
  }
  removeItemClick(item: TactusRecord) {
    this.deleteItem.emit(item);
  }
}
