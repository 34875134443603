import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from "@angular/core";
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from "@angular/forms";
import { TranslateService } from "@ngx-translate/core";
import * as moment from "moment";
import { FileUploadService } from "src/app/core/file-upload.service";
import { ToastService } from "src/app/core/toast.service";
import { Helpers } from "src/app/services/app.helpers";
import { environment } from "src/environments/environment";
import { FileType } from "../medical-record.model";

@Component({
  selector: "app-add-medical-report",
  templateUrl: "./add-medical-report.component.html",
  styleUrls: ["./add-medical-report.component.scss"],
})
export class AddMedicalReportComponent implements OnInit, OnChanges {
  @Input() model: any;
  @Output() closed: EventEmitter<any> = new EventEmitter<any>();
  @Output() success: EventEmitter<any> = new EventEmitter<any>();

  form!: FormGroup;
  serverPath = environment.serverPath;
  isFormSubmitting = false;

  today = moment().toDate();

  fileTypes = [
    { id: FileType.Pdf, name: this.translationService.instant("educationPage.popup.Pdf") },
    { id: FileType.Picture, name: this.translationService.instant("educationPage.popup.Picture") },
  ];

  constructor(
    private fb: FormBuilder,
    private translationService: TranslateService,
    private uploadService: FileUploadService,
    private toastService: ToastService
  ) {}

  get selectedFileTypeId() {
    return this.form.controls["FileType"] &&
      this.form.controls["FileType"].value &&
      (this.form.controls["FileType"].value.id || this.form.controls["FileType"].value.id === 0)
      ? this.form.controls["FileType"].value.id
      : undefined;
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes["model"]) this.createForm();
  }

  ngOnInit() {
    this.createForm();

    this.translationService.onLangChange.subscribe((x) => {
    });
  }

  uploadFile(event: any) {
    this.isFormSubmitting = true;
    this.uploadService.upload(event.files[0]).subscribe(
      (x: any) => {
        this.isFormSubmitting = false;
        if (x.path) {
          this.form.controls["Path"].setValue(x.path);
        }
      },
      (err) => {
        this.isFormSubmitting = false;
        this.toastService.generalErrorMessage();
      }
    );
  }

  createForm() {
    this.form = this.fb.group({
      ReportTitle: [(this.model && this.model.code.text) || "", Validators.required],
      DatePerformed: [(this.model && moment(this.model.performedDateTime).toDate()) || "", Validators.required],
      Path: [(this.model && this.model.extension && this.model.extension[0].valueString) || ""],
      FileType: [
        (this.model &&
          this.model.extension &&
          this.fileTypes.find((x) => x.id === (this.model.extension[0].valueString.split(".")[1] === "pdf" ? 1 : 0))) ||
          "",
      ],
    });
  }

  onAddElement(elem: string) {
    (this.form.get(elem) as FormArray).push(new FormControl("", Validators.required));
  }

  onRemoveElement(elem: string) {
    const length = (this.form.get(elem) as FormArray).controls.length;
    (this.form.get(elem) as FormArray).removeAt(length - 1);
  }

  onClose() {
    this.closed.emit();
    // this.form.reset();
  }

  save() {
    Helpers.markFormGroupTouched(this.form);
    Helpers.markFormGroupDirty(this.form);

    if (!this.form.valid) {
      this.toastService.errorMessage(this.translationService.instant("alerts.INVALID_FORM_CORRECT_THEM"));
      return;
    }

    this.isFormSubmitting = true;

    let memberModel: any = {};

    memberModel["resourceType"] = "DiagnosticReport";
    memberModel["status"] = "registered";
    memberModel["code"] = [
      {
        coding: [
          {
            system: "http://clinical.iti.org",
            code: this.form.controls["ReportTitle"].value,
            display: this.form.controls["ReportTitle"].value,
          },
        ],
        text: this.form.controls["ReportTitle"].value,
      },
    ];

    memberModel["effectiveDateTime"] = moment(this.form.controls["DatePerformed"].value).format("YYYY-MM-DD");
    if (this.form.controls["Path"].value) {
      memberModel["extension"] = [{ valueString: this.form.controls["Path"].value, url: "file_url" }];
    }

    if (this.model) {
      delete this.model.meta;
      memberModel = { ...this.model, ...memberModel };
    }

    this.success.emit(memberModel);
    this.isFormSubmitting = false;
    this.form.reset();
    this.closed.emit();
  }

  removeAttachment(attachment: any, idx: any) {
    delete this.model.extension;
    this.form.controls["Path"].setValue("");
  }
}
