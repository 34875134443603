<p-sidebar [(visible)]="display" [baseZIndex]="10000">
  <aside>
    <div (click)="toggleMenu()">
      <i class="x-icon fas fa-times"></i>
    </div>
    <div class="d-flex flex-column" style="height: 100%; overflow-y: auto !important;">
      <div class="sidebar-header">
        <img src="/assets/images/favicon.png" alt="" height="54" />
      </div>
      <div class="menu flex-grow-1">
        <ul>
          <li *ngFor="let item of menu" [ngClass]="{ 'not-hover': item.subMenuItems }">
            <div *ngIf="!item.subMenuItems">
              <a [routerLink]="item.routerLink" routerLinkActive="active" (click)="toggleMenu()">
                <i *ngIf="item.iconClass && item.iconClass != ''" [ngClass]="item.iconClass"
                  class="fa-fw fas fa-bars"></i>
                <span>{{ item.text | translate }}</span>
              </a>
            </div>
            <div *ngIf="item.subMenuItems" class="">
              <div class="sub-menu">
                <i *ngIf="item.iconClass && item.iconClass != ''" [ngClass]="item.iconClass"
                  class="fa-fw fas fa-bars"></i>
                <span>{{ item.text | translate }}</span>
              </div>
              <div>
                <ul>
                  <li *ngFor="let subItem of item.subMenuItems">
                    <a [routerLink]="subItem.routerLink" routerLinkActive="active" style="padding-left: 60px !important"
                      (click)="toggleMenu()">
                      <i *ngIf="subItem.iconClass && subItem.iconClass != ''" [ngClass]="subItem.iconClass"
                        class="fa-fw fas fa-bars"></i>
                      <span>{{ subItem.text | translate }}</span>
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </li>
          <li>
            <div>
              <a routerLink="#" routerLinkActive="active" (click)="logoutUser()">
                <i class="fas fa-sign-out-alt"></i>
                <span>{{ "sidebar.Logout" | translate }}</span>
              </a>
            </div>
          </li>
        </ul>
      </div>
      <div class="hl7-logo">
        <img src="/assets/images/hl7-logo.png" />
      </div>
    </div>
  </aside>
</p-sidebar>