<div class="card">
  <!-- <div class="card-header">
    <div class="flex-grow-1 title">This is the title</div>
  </div> -->
  <div class="card-body flex-grow-1" style="padding: 10px">
    <div class="iti-timeline-container">
      <div class="row">
        <div class="col-lg-12 col-xl-12">
          <div class="timeline">
            <div *ngIf="!items || items.length === 0" class="center-text">
              * {{ "No records found for the patient" | translate }}
            </div>
            <div class="item-container">
              <div class="iti-timeline-item" *ngFor="let item of items" [ngClass]="[item.isRead ? '' : 'not-read']">
                <ng-container>
                  <div class="dot" [ngStyle]="{ 'border-color': item.color }"></div>
                  <div class="arrow" [ngStyle]="{ 'border-color': item.color }"></div>
                  <div class="item-content" [ngStyle]="{ 'border-color': item.color }">
                    <div>
                      <div class="header">
                        {{ item.header }}
                        <div class="read-icon">
                          <a (click)="editItemClick(item)" class="pointer pr-1"
                            ><i class="fas fa-edit" style="color: #ef9c40"></i
                          ></a>
                          <a (click)="removeItemClick(item)" class="pointer"
                            ><i class="fas fa-times" style="color: #ef5a40"></i
                          ></a>
                        </div>
                      </div>
                      <div class="date">{{ formatDate(item.date, "DD-MM-YYYY, HH:mm") }}</div>
                      <div class="" style="white-space: pre-line; max-height: 550px; max-width: 500px">
                        {{ item.data }}
                      </div>
                    </div>
                  </div>
                </ng-container>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
