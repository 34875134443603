import { Component, OnInit, Input, EventEmitter, Output, SimpleChanges, OnChanges } from "@angular/core";
import { FormGroup, FormBuilder, Validators, FormArray, FormControl } from "@angular/forms";
import { TranslateService } from "@ngx-translate/core";
import { Helpers } from "src/app/services/app.helpers";
import * as moment from "moment";
import { Lookup } from "src/app/core/models/lookup.model";
import { environment } from "src/environments/environment";
import { FileUploadService } from "src/app/core/file-upload.service";
import { FileType } from "../medical-record.model";
import { ToastService } from "src/app/core/toast.service";
import * as fhir from "fhir-stu3";

@Component({
  selector: "app-add-encounter",
  templateUrl: "./add-encounter.component.html",
  styleUrls: ["./add-encounter.component.scss"],
})
export class AddEncounterComponent implements OnInit, OnChanges {
  @Input() model: any;
  @Output() closed: EventEmitter<any> = new EventEmitter<any>();
  @Output() success: EventEmitter<any> = new EventEmitter<any>();

  form!: FormGroup;
  serverPath = environment.serverPath;
  isFormSubmitting = false;
  fileExtension: any = null;

  fileTypes = [
    { id: FileType.Pdf, name: this.translationService.instant("educationPage.popup.Pdf") },
    { id: FileType.Picture, name: this.translationService.instant("educationPage.popup.Picture") },
  ];

  constructor(
    private fb: FormBuilder,
    private translationService: TranslateService,
    private uploadService: FileUploadService,
    private toastService: ToastService
  ) {}

  get selectedFileTypeId() {
    return this.form.controls["FileType"] &&
      this.form.controls["FileType"].value &&
      (this.form.controls["FileType"].value.id || this.form.controls["FileType"].value.id === 0)
      ? this.form.controls["FileType"].value.id
      : undefined;
  }

  get dischargeCompletedControls() {
    return this.form.get("dischargeCompleted") as FormGroup;
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes["model"]) this.createForm();
  }

  ngOnInit() {
    this.createForm();

    this.translationService.onLangChange.subscribe((x) => {});
  }

  uploadFile(event: any) {
    this.isFormSubmitting = true;
    this.uploadService.upload(event.files[0]).subscribe(
      (x: any) => {
        this.isFormSubmitting = false;
        if (x.path) {
          this.form.controls["Path"].setValue(x.path);
        }
      },
      (err) => {
        this.isFormSubmitting = false;
        this.toastService.generalErrorMessage();
      }
    );
  }

  createForm() {
    this.fileExtension = this.model?.extension?.find((x: fhir.Extension) => x.url === "file_url");
    const completedExtension =
      this.model?.extension?.findIndex((x: fhir.Extension) => x.url === "completed") > -1 &&
      JSON.parse(this.model?.extension?.find((x: fhir.Extension) => x.url === "completed").valueString);
    this.form = this.fb.group({
      reason: [
        (this.model && (this.model.reason[0].text || this.model.reason[0].coding[0].display)) || "",
        Validators.required,
      ],
      start: [(this.model && this.model.period && moment(this.model.period.start).toDate()) || "", Validators.required],
      end: [(this.model && this.model.period && moment(this.model.period.end).toDate()) || ""],
      Path: [(this.fileExtension && this.fileExtension.valueString) || ""],
      FileType: [
        (this.model &&
          this.model.extension &&
          this.fileTypes.find((x) => x.id === (this.fileExtension.valueString.split(".")[1] === "pdf" ? 1 : 0))) ||
          "",
      ],
      dischargeCompleted: this.fb.group({
        completed: [completedExtension.completed || false, Validators.required],
        medicationAfterDischarge: [completedExtension.medicationAfterDischarge || "Humira", Validators.required],
        NYHAClass: [completedExtension.NYHAClass || "1", Validators.required],
        dryWeight: [completedExtension.dryWeight || "112", Validators.required],
        systolicBloodPressure: [completedExtension.systolicBloodPressure || "112", Validators.required],
        diastolicBloodPressure: [completedExtension.diastolicBloodPressure || "130", Validators.required],
        heartRate: [completedExtension.heartRate || "80", Validators.required],
        haemoglobin: [completedExtension.haemoglobin || "23", Validators.required],
        creatinine: [completedExtension.creatinine || "30", Validators.required],
        urea: [completedExtension.urea || "89", Validators.required],
        eGFR: [completedExtension.eGFR || "11", Validators.required],
        followUp: [completedExtension.followUp || "Follow the exercise plan", Validators.required],
        notes: [completedExtension.notes || "Important to take your medication on time", Validators.required],
        poc: [completedExtension.poc || "Van Hermet", Validators.required],
      }),
    });
  }

  onAddElement(elem: string) {
    (this.form.get(elem) as FormArray).push(new FormControl("", Validators.required));
  }

  onRemoveElement(elem: string) {
    const length = (this.form.get(elem) as FormArray).controls.length;
    (this.form.get(elem) as FormArray).removeAt(length - 1);
  }

  onClose() {
    this.closed.emit();
    // this.form.reset();
  }

  save() {
    Helpers.markFormGroupTouched(this.form);
    Helpers.markFormGroupDirty(this.form);

    if (!this.form.valid) {
      this.toastService.errorMessage(this.translationService.instant("alerts.INVALID_FORM_CORRECT_THEM"));
      return;
    }

    this.isFormSubmitting = true;

    let memberModel: any = {};

    memberModel["resourceType"] = "Encounter";
    memberModel["status"] = "finished";
    memberModel["reason"] = [
      {
        coding: [
          {
            system: "http://clinical.iti.org/encounter-reason",
            code: this.form.controls["reason"].value,
            display: this.form.controls["reason"].value,
          },
        ],
        text: this.form.controls["reason"].value,
      },
    ];

    if (this.form.controls["end"].value) {
      memberModel["period"] = {
        start: moment(this.form.controls["start"].value).format("YYYY-MM-DD"),
        end: moment(this.form.controls["end"].value).format("YYYY-MM-DD"),
      };
    } else {
      memberModel["period"] = {
        start: moment(this.form.controls["start"].value).format("YYYY-MM-DD"),
      };
    }

    memberModel["extension"] = [];

    if (this.form.controls["Path"].value) {
      memberModel["extension"].push({ valueString: this.form.controls["Path"].value, url: "file_url" });
    }

    if ((this.form.get("dischargeCompleted") as FormGroup)?.get("completed")?.value) {
      memberModel["extension"].push({
        valueString: JSON.stringify(this.form.getRawValue().dischargeCompleted),
        url: "completed",
      });
    }

    if (this.model) {
      delete this.model.meta;
      memberModel = { ...this.model, ...memberModel };
    }

    this.success.emit(memberModel);
    this.isFormSubmitting = false;
    this.form.reset();
    this.closed.emit();
  }

  removeAttachment(attachment: any, idx: any) {
    delete this.model.extension;
    this.form.controls["Path"].setValue("");
  }
}
