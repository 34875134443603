export const EDUCATIONAL_LEVELS = ["12 years", "12-14 years", "14 years"];

export const TECH_FAMILIARITY = ["a lot", "moderately", "a little", "not at all"];

export const LIVE_ALONE = ["yes", "no"];

export const CHILDREN = ["yes", "no"];

export const MARITAL_STATUS = ["single", "married", "divorced", "widowed"];

export const OCCUPATION = ["retired", "full-time work", "part-time work", "unemployed", "never employed"];

export const Extensions = {
  caseManager: "case-manager",
  nationality: "http://local-system/nationality",
  educationalLevel: "http://local-system/educationalLevel",
  techFamiliarity: "http://local-system/techFamiliarity",
  liveAlone: "http://local-system/liveAlone",
  children: "http://local-system/children",
  occupation: "http://local-system/occupation",
  maritalStatus: "http://local-system/maritalStatus",
};
