import { Injectable } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import { Subject } from "rxjs";

export interface IWindow extends Window {
  webkitSpeechRecognition?: any;
  SpeechSynthesisUtterance?: any;
  speechSynthesis: any;
}

@Injectable({
  providedIn: "root",
})
export class SpeechRecognitionService {
  recordingStateChanged = new Subject<boolean>();
  private speechRecognitionAvailable = false;
  private recognition!: SpeechRecognition;
  private recording = false;

  constructor(private translateService: TranslateService) {}

  isAvailable() {
    return this.speechRecognitionAvailable;
  }

  start() {
    if (this.recognition && !this.recording) {
      this.recognition.start();
    }
  }

  stop() {
    if (this.recognition && this.recording) {
      this.recognition.stop();
    }
  }

  isRecording() {
    return this.recordingStateChanged.asObservable();
  }

  init(resultCB?: (text: string) => void, errorCB?: (error: { error: string }) => void) {
    this.speechRecognitionAvailable = "webkitSpeechRecognition" in window || "SpeechRecognition" in window;
    if (this.speechRecognitionAvailable) {
      const { webkitSpeechRecognition }: IWindow = window as IWindow;

      this.recognition = new webkitSpeechRecognition();

      this.recognition.continuous = true;
      this.recognition.interimResults = true;
      this.recognition.lang = this.translateService.currentLang;

      this.recognition.onstart = () => {
        this.recording = true;
        this.recordingStateChanged.next(true);
      };

      this.recognition.onend = () => {
        this.recording = false;
        this.recordingStateChanged.next(false);
      };

      this.recognition.onresult = (event: SpeechRecognitionEvent) => {
        if (event.results.length > 0) {
          const res = event.results[event.results.length - 1];
          if (res.isFinal) {
            this.recognition.stop();
            this.recording = false;
            this.recordingStateChanged.next(false);
            if (resultCB) {
              resultCB(res[0].transcript);
            }
          }
        }
      };

      this.recognition.onerror = (event: SpeechRecognitionErrorEvent) => {
        console.log("ERROR:", event);
        this.recording = false;
        this.recordingStateChanged.next(false);
        if (errorCB) {
          errorCB(event);
        }
      };
    } else {
      if (errorCB) {
        this.recording = false;
        this.recordingStateChanged.next(false);
        errorCB({ error: "NOT_SUPPORTED" });
      }
    }
  }
}
