<div class="medical-record">
  <p-card>
    <div class="bold" class="header d-flex justify-content-between">
      <label class="font-size-18">{{ "VoicePitch" | translate }}</label>
      <button
        icon="fas fa-plus"
        class="p-button-raised p-button-success"
        pButton
        [label]="'StartStreamingSession' | translate"
        (click)="startStreamingSession()"
      ></button>
    </div>
    <div class="content">Content</div>
  </p-card>
</div>

<p-dialog
  appendTo="body"
  *ngIf="showStreamingPopUp"
  [header]="'AddTactusRecord' | translate"
  [(visible)]="showStreamingPopUp"
  [modal]="true"
  [style]="{ 'min-width': '70vw', 'max-width': '100%', 'min-height': '20vh', height: 'auto' }"
  [contentStyle]="{ overflow: 'visible' }"
  (visibleChange)="destroyActiveSession()"
>
  <button
    icon="fas fa-minus"
    class="p-button-raised p-button-success"
    pButton
    [label]="'destroyStreamingSession' | translate"
    (click)="destroyActiveSession()"
  ></button>
</p-dialog>
