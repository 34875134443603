import { Injectable } from "@angular/core";
import { HttpClient, HttpParams } from "@angular/common/http";
import { environment } from "src/environments/environment";

@Injectable({
  providedIn: "root",
})
export class AssistantService {
  constructor(private httpClient: HttpClient) {}

  getAnswerFromAgent(input: string) {
    return this.httpClient.post<{ action: string; text: string; name: string }>(
      `${environment.serverPath}/api/assistant/send`,
      { message: input }
    );
  }
}
