import { menuItem } from "./app.constants";
import { Role } from "../core/enum/Role";

export const menuItems: Array<menuItem> = [
  // {
  //     routerLink: "/dashboard",
  //     iconClass: "fas fa-tachometer-alt",
  //     text: "sidebar.Dashboard",
  //     sidebarMenuItem: true,
  //     tooltip: "sidebar.Dashboard"
  // },
  {
    routerLink: "/patient-view",
    iconClass: "fas fa-user-injured",
    text: "sidebar.Dashboard",
    sidebarMenuItem: true,
    roles: [Role.Patient],
    tooltip: "sidebar.Dashboard",
  },
  {
    routerLink: "/nutrition-record-diary",
    iconClass: "fas fa-calendar-check",
    text: "sidebar.NutritionRecordDiary",
    sidebarMenuItem: true,
    roles: [Role.Patient],
    tooltip: "sidebar.NutritionRecordDiary",
  },
  {
    routerLink: "/patients",
    iconClass: "fas fa-user-injured",
    text: "breadcrump.Patients",
    sidebarMenuItem: true,
    roles: [Role.Doctor, Role.CareCoordinator],
    tooltip: "breadcrump.Patients",
    subMenuItems: [
      {
        routerLink: "/patients",
        iconClass: "fas fa-chevron-right",
        text: "sidebar.Patients",
        sidebarMenuItem: true,
        tooltip: "sidebar.Patients",
      },
      // {
      //   routerLink: "/maggic-calculator",
      //   iconClass: "fas fa-chevron-right",
      //   text: "Maggic",
      //   sidebarMenuItem: true,
      //   tooltip: "Maggic",
      // },
      // {
      //   routerLink: "/patients-analysis",
      //   iconClass: "fas fa-chevron-right",
      //   text: "Graph analysis",
      //   sidebarMenuItem: true,
      //   tooltip: "Graph analysis",
      // },
      // {
      //   routerLink: "/compare/moment-in-time",
      //   iconClass: "fas fa-chevron-right",
      //   text: "Compare analysis",
      //   sidebarMenuItem: true,
      //   tooltip: "Compare analysis",
      // },
      // {
      //   routerLink: "/patients-analysis",
      //   iconClass: null,
      //   text: "sidebar.Analysis",
      //   sidebarMenuItem: true,
      //   tooltip: "sidebar.Analysis",
      // },
      {
        routerLink: "/search",
        iconClass: "fas fa-chevron-right",
        text: "sidebar.Search",
        sidebarMenuItem: true,
        tooltip: "sidebar.Search",
      },
    ],
  },
  {
    routerLink: "/patient-appointments",
    iconClass: "fas fa-calendar-alt",
    text: "sidebar.Appointments",
    sidebarMenuItem: true,
    roles: [Role.Patient],
    tooltip: "sidebar.Appointments",
  },
  // {
  //   routerLink: "/nutrition",
  //   iconClass: "fas fa-utensils",
  //   text: "sidebar.NutritionPlans",
  //   sidebarMenuItem: true,
  //   roles: [Role.Doctor],
  //   tooltip: "sidebar.NutritionPlans",
  //   // subMenuItems: [
  //   //   {
  //   //     routerLink: "/nutrition",
  //   //     text: "sidebar.NutritionPlans",
  //   //     sidebarMenuItem: true,
  //   //     roles: [Role.Doctor],
  //   //     tooltip: "sidebar.NutritionPlans",
  //   //   },
  //   //   {
  //   //     routerLink: "/meals",
  //   //     text: "sidebar.Meals",
  //   //     sidebarMenuItem: true,
  //   //     roles: [Role.Doctor],
  //   //     tooltip: "sidebar.Meals",
  //   //   },
  //   // ],
  // },
  {
    routerLink: "/appointments",
    iconClass: "fas fa-calendar-alt",
    text: "sidebar.Appointments",
    sidebarMenuItem: true,
    roles: [Role.Doctor, Role.CareCoordinator],
    tooltip: "sidebar.Appointments",
  },
  {
    routerLink: "/admin/all-users",
    iconClass: "fas fa-users",
    text: "sidebar.Users",
    sidebarMenuItem: true,
    roles: [Role.Admin],
    tooltip: "sidebar.Users",
  },
  {
    routerLink: "/admin/misbehaviour-reports",
    iconClass: "fas fa-flag",
    text: "Misb. Reports",
    sidebarMenuItem: true,
    roles: [Role.Admin],
    tooltip: "Misb. Reports",
  },
  {
    routerLink: "/admin/services",
    iconClass: "fas fa-users",
    text: "Services/Apps",
    sidebarMenuItem: true,
    roles: [Role.Admin],
    tooltip: "Services/Apps",
  },
  // {
  //   routerLink: "/exercises",
  //   iconClass: "fas fa-running",
  //   text: "sidebar.Exercises",
  //   sidebarMenuItem: true,
  //   roles: [Role.Doctor],
  //   tooltip: "sidebar.Exercises",
  // },
  {
    routerLink: "/questionnaires",
    iconClass: "fas fa-question-circle",
    text: "Protocol questionnaires",
    sidebarMenuItem: true,
    roles: [Role.Doctor, Role.Admin],
    tooltip: "Protocol questionnaires",
  },
  {
    routerLink: "/admin/doctor-questionnaires",
    iconClass: "fas fa-question-circle",
    text: "Practitioner questionnaires",
    sidebarMenuItem: true,
    roles: [Role.Admin],
    tooltip: "Practitioner questionnaires",
  },
  {
    routerLink: "/education",
    iconClass: "fas fa-graduation-cap",
    text: "sidebar.Education",
    sidebarMenuItem: true,
    roles: [Role.Doctor, Role.Patient, Role.Admin],
    tooltip: "sidebar.Education",
  },
  {
    routerLink: "/catalogue",
    iconClass: "fas fa-tv",
    text: "CatalogueApps",
    sidebarMenuItem: true,
    roles: [Role.Doctor, Role.CareCoordinator],
    tooltip: "sidebar.tooltips.Chat",
  },
  {
    routerLink: "/chat",
    iconClass: "fas fa-comments",
    text: "sidebar.Chat",
    sidebarMenuItem: true,
    roles: [Role.Doctor, Role.Patient, Role.CareCoordinator],
    tooltip: "sidebar.tooltips.Chat",
  },
  // {
  //   routerLink: "/leaderboard",
  //   iconClass: "fas fa-vihara",
  //   text: "Leaderboard",
  //   sidebarMenuItem: true,
  //   roles: [Role.Doctor, Role.CareGiver, Role.Patient, Role.Admin, Role.CareCoordinator],
  //   tooltip: "Leaderboard",
  // },
  // {
  //   routerLink: "/food-supplements",
  //   iconClass: "fas fa-pills",
  //   text: "sidebar.FoodSupplements",
  //   sidebarMenuItem: true,
  //   roles: [Role.Doctor],
  //   tooltip: "sidebar.FoodSupplements",
  // },
  {
    routerLink: "/notifications",
    iconClass: "fas fa-bell",
    text: "sidebar.Notifications",
    sidebarMenuItem: true,
    roles: [Role.Doctor, Role.Patient, Role.CareCoordinator],
    tooltip: "sidebar.Notifications",
  },
  // {
  //   routerLink: "/critical-notifications",
  //   iconClass: "fas fa-exclamation-circle",
  //   text: "patientsPage.criticalNotifications",
  //   sidebarMenuItem: true,
  //   roles: [Role.Doctor, Role.CareCoordinator],
  //   tooltip: "patientsPage.criticalNotifications",
  // },
  // {
  //   routerLink: "/dss",
  //   iconClass: "fas fa-pencil-ruler",
  //   text: "Protocol DSS",
  //   sidebarMenuItem: true,
  //   roles: [Role.Doctor, Role.Admin],
  //   tooltip: "Protocol DSS",
  // },
  {
    routerLink: "/questionnaires",
    iconClass: "fas fa-pencil-ruler",
    text: "Protocol",
    sidebarMenuItem: true,
    roles: [Role.CareCoordinator],
    tooltip: "Protocol",
    subMenuItems: [
      {
        routerLink: "/questionnaires",
        iconClass: "fas fa-chevron-right",
        text: "Protocol questionnaires",
        sidebarMenuItem: true,
        tooltip: "Protocol questionnaires",
      },
      {
        routerLink: "/dss",
        iconClass: "fas fa-chevron-right",
        text: "Protocol DSS",
        sidebarMenuItem: true,
        tooltip: "Protocol DSS",
      },
      {
        routerLink: "/education",
        iconClass: "fas fa-chevron-right",
        text: "sidebar.Education",
        sidebarMenuItem: true,
        tooltip: "sidebar.Education",
      },
      {
        routerLink: "/exercises",
        iconClass: "fas fa-chevron-right",
        text: "sidebar.Exercises",
        sidebarMenuItem: true,
        tooltip: "sidebar.Exercises",
      },
    ],
  },
  {
    routerLink: "/account",
    iconClass: "fas fa-user-circle",
    text: "sidebar.Account",
    sidebarMenuItem: true,
    roles: [Role.Doctor, Role.Patient, Role.CareCoordinator, Role.Developer],
    tooltip: "sidebar.Account",
  },
  {
    routerLink: "/helpdesk",
    iconClass: "fas fa-inbox",
    text: "HelpDesk",
    sidebarMenuItem: true,
    roles: [Role.Doctor, Role.Patient, Role.Admin, Role.CareGiver, Role.Developer, Role.CareCoordinator],
    tooltip: "HelpDesk",
  },
  // {
  //   routerLink: "/mass-assign",
  //   iconClass: "fas fa-drafting-compass",
  //   text: "sidebar.MassAssign",
  //   sidebarMenuItem: true,
  //   roles: [Role.Doctor],
  //   tooltip: "sidebar.MassAssign",
  // },
];
