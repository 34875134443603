import { Component, OnInit, Input, EventEmitter, Output, SimpleChanges, OnChanges } from "@angular/core";
import { FormGroup, FormBuilder, Validators, FormArray, FormControl } from "@angular/forms";
import { TranslateService } from "@ngx-translate/core";
import { Helpers } from "src/app/services/app.helpers";
import * as moment from "moment";
import { Lookup } from "src/app/core/models/lookup.model";
import { environment } from "src/environments/environment";
import { FileUploadService } from "src/app/core/file-upload.service";
import { FileType } from "../medical-record.model";
import { ToastService } from "src/app/core/toast.service";
import { HttpClient } from "@angular/common/http";

@Component({
  selector: "app-add-condition",
  templateUrl: "./add-condition.component.html",
  styleUrls: ["./add-condition.component.scss"],
})
export class AddConditionComponent implements OnInit, OnChanges {
  @Input() model: any;
  @Output() closed: EventEmitter<any> = new EventEmitter<any>();
  @Output() success: EventEmitter<any> = new EventEmitter<any>();

  form!: FormGroup;
  serverPath = environment.serverPath;
  isFormSubmitting = false;

  fileTypes = [
    { id: FileType.Pdf, name: this.translationService.instant("educationPage.popup.Pdf") },
    { id: FileType.Picture, name: this.translationService.instant("educationPage.popup.Picture") },
  ];

  clinicalStatusList = [
    { id: 0, name: this.translationService.instant("PatientRecord.active"), value: "active" },
    { id: 1, name: this.translationService.instant("PatientRecord.recurrence"), value: "recurrence" },
    { id: 2, name: this.translationService.instant("PatientRecord.inactive"), value: "inactive" },
    { id: 3, name: this.translationService.instant("PatientRecord.remission"), value: "remission" },
    { id: 4, name: this.translationService.instant("PatientRecord.resolved"), value: "resolved" },
  ];
  conditions: any[] = [];

  constructor(
    private fb: FormBuilder,
    private translationService: TranslateService,
    private uploadService: FileUploadService,
    private toastService: ToastService,
    private httpClient: HttpClient
  ) {}

  get selectedFileTypeId() {
    return this.form.controls["FileType"] &&
      this.form.controls["FileType"].value &&
      (this.form.controls["FileType"].value.id || this.form.controls["FileType"].value.id === 0)
      ? this.form.controls["FileType"].value.id
      : undefined;
  }

  get selectedCondition() {
    return this.form.get("Condition")?.value || null;
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes["model"]) this.createForm();
  }

  ngOnInit() {
    this.createForm();
    this.getMedicalConditions();

    this.translationService.onLangChange.subscribe((x) => {});
  }

  getMedicalConditions() {
    this.httpClient.get<any[]>(`${environment.serverPath}/api/page/medical-conditions`).subscribe((res) => {
      this.conditions = res;
      this.conditions.push({ id: "other", label: this.translationService.instant("Other"), code: "other" });
      this.createForm();
    });
  }

  uploadFile(event: any) {
    this.isFormSubmitting = true;
    this.uploadService.upload(event.files[0]).subscribe(
      (x: any) => {
        this.isFormSubmitting = false;
        if (x.path) {
          this.form.controls["Path"].setValue(x.path);
        }
      },
      (err) => {
        this.isFormSubmitting = false;
        this.toastService.generalErrorMessage();
      }
    );
  }

  createForm() {
    this.form = this.fb.group({
      OtherCondition: [""],
      Condition: [
        (this.model && this.conditions.find((x) => x.code === this.model.code?.coding?.[0]?.code)) || "",
        Validators.required,
      ],
      Status: [
        (this.model && this.clinicalStatusList.find((x) => x.value === this.model.clinicalStatus)) || "",
        Validators.required,
      ],
      From: [(this.model && moment(this.model.onsetPeriod.start).toDate()) || "", Validators.required],
      Until: [(this.model && moment(this.model.onsetPeriod.end).toDate()) || ""],
      Notes: this.fb.array([]),
      Path: [(this.model && this.model.extension && this.model.extension[0].valueString) || ""],
      FileType: [
        (this.model &&
          this.model.extension &&
          this.fileTypes.find((x) => x.id === (this.model.extension[0].valueString.split(".")[1] === "pdf" ? 1 : 0))) ||
          "",
      ],
    });

    if (this.model && this.model.note && this.model.note.length !== 0) {
      this.model.note.forEach((note: any) => {
        (this.form.controls["Notes"] as FormArray).push(new FormControl(note.text, Validators.required));
      });
    }
  }

  onAddElement(elem: string) {
    (this.form.get(elem) as FormArray).push(new FormControl("", Validators.required));
  }

  onRemoveElement(elem: string) {
    const length = (this.form.get(elem) as FormArray).controls.length;
    (this.form.get(elem) as FormArray).removeAt(length - 1);
  }

  onClose() {
    this.closed.emit();
    // this.form.reset();
  }

  save() {
    Helpers.markFormGroupTouched(this.form);
    Helpers.markFormGroupDirty(this.form);

    if (!this.form.valid) {
      this.toastService.errorMessage(this.translationService.instant("alerts.INVALID_FORM_CORRECT_THEM"));
      return;
    }

    this.isFormSubmitting = true;

    let memberModel: any = {};

    const formMedicalCondition = {
      system: "http://clinical.iti.org",
      code: this.form.controls["Condition"].value.code,
      display: this.form.controls["Condition"].value.label,
    };
    if (formMedicalCondition.code === "other") {
      formMedicalCondition.code = this.form.get("OtherCondition")?.value;
      formMedicalCondition.display = this.form.get("OtherCondition")?.value;
    }

    memberModel["resourceType"] = "Condition";
    memberModel["clinicalStatus"] = this.form.controls["Status"].value.value;
    memberModel["verificationStatus"] = "confirmed";
    memberModel["code"] = [
      {
        coding: [
          {
            system: formMedicalCondition.system,
            code: formMedicalCondition.code,
            display: formMedicalCondition.display,
          },
        ],
        text: formMedicalCondition.display,
      },
    ];

    if (this.form.controls["Until"].value) {
      memberModel["onsetPeriod"] = {
        start: moment(this.form.controls["From"].value).format("YYYY-MM-DD"),
        end: moment(this.form.controls["Until"].value).format("YYYY-MM-DD"),
      };
    } else {
      memberModel["onsetPeriod"] = {
        start: moment(this.form.controls["From"].value).format("YYYY-MM-DD"),
      };
    }
    if (this.form.controls["Notes"].value.length !== 0) {
      memberModel["note"] = this.form.controls["Notes"].value.map((x: any) => ({
        text: x,
      }));
    }

    if (this.form.controls["Path"].value) {
      memberModel["extension"] = [{ valueString: this.form.controls["Path"].value, url: "file_url" }];
    }

    if (this.model) {
      delete this.model.meta;
      memberModel = { ...this.model, ...memberModel };
    }

    this.success.emit(memberModel);
    this.isFormSubmitting = false;
    this.form.reset();
    this.closed.emit();
  }

  removeAttachment(attachment: any, idx: any) {
    delete this.model.extension;
    this.form.controls["Path"].setValue("");
  }
}
